import React, { useState, useContext } from 'react';
import { UserForm } from './user-form.container';
import { userProfileService } from '../../../../services';
import { UserProfileContext } from '../../../../store/user-profile.context';
import { useHistory } from 'react-router';
import { notifyError, notifySuccess } from '../../../../services/toast.service';

export function AddUserForm() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const userProfileContext = useContext(UserProfileContext);

    const submitUser = async (event) => {
        setLoading(true);

        event.preventDefault();
        const user = {
            firstName,
            lastName,
            emailAddress: email,
            accountState: '',
            nigelId: '',
            isActive: true,
            partnerId: userProfileContext.userProfile.partnerId,
            roles: userRoles
        };

        userProfileService
            .addUser(user)
            .then(() => {
                setLoading(false);
                cleanUpForm();
                notifySuccess(
                    `New user added successfully, an email has been set to ${firstName} ${lastName}`
                );
                gotoUsersPage();
            })
            .catch(() => {
                setLoading(false);
                notifyError(
                    'Something went wrong while adding the user, please check if the user exists in the users table.'
                );
            });
    };

    const cleanUpForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
    };

    const history = useHistory();

    const gotoUsersPage = () => {
        history.push('users');
    };

    return (
        <div>
            <UserForm
                isEdit={false}
                firstName={firstName}
                lastName={lastName}
                email={email}
                active={active}
                userRoles={userRoles}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setActive={setActive}
                setUserRoles={setUserRoles}
                loading={loading}
                submitUser={submitUser}
                onCancel={gotoUsersPage}
            />
        </div>
    );
}
