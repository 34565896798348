export const styles = {
    mt3: { marginTop: 3 },
    mb: { marginLeft: 'auto' },
    dataWidth: {
        width: '100%'
    },
    plusIcon: { marginLeft: 'auto' },
    dataText: { overflowWrap: 'break-word', cursor: 'pointer' },
    dataActions: { display: 'flex', flexDirection: 'row' }
};
