import { formatData, formatFilterForRequest } from '../utils/data-table-helpers';
import { httpService } from './http.service';

async function addRole(role) {
    const url = `roles/`;
    return await httpService.post(url, role);
}

async function getPartnerRoles(partnerId) {
    const url = `roles?partnerId=${partnerId}`;
    const response = await httpService.get(url);
    return await response.data;
}

async function getRoles(options) {
    const { pageSize, pageNumber, sortBy, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `roles?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[${
        sortBy.field
    }]=${sortBy.sort === 'asc' ? 1 : -1}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getRolesBySearch(options) {
    const { pageSize, pageNumber, searchText, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `roles?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[roleName]=1&$or[0][roleDescription][$search]=${searchText}&$or[1][roleName][$search]=${searchText}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getRolesByFilter(options) {
    const { pageSize, pageNumber, filter, partnerId } = options;
    const skip = pageSize * pageNumber;
    const filterQuery = formatFilterForRequest(filter);

    const url = `roles?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[roleName]=1&${filterQuery}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function setRoleIsActive(id, state) {
    const url = `roles/${id}`;
    const response = await httpService.patch(url, {
        isActive: state
    });
    return await response.data;
}

async function getRoleById(id) {
    const url = `roles/?_id=${id}`;
    const response = await httpService.get(url);
    return await response.data[0];
}

async function editRole(role) {
    const url = `roles/${role._id}`;
    return await httpService.patch(url, role);
}

export const rolesService = {
    getPartnerRoles,
    getRolesBySearch,
    getRolesByFilter,
    getRoles,
    setRoleIsActive,
    addRole,
    getRoleById,
    editRole
};
