import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { UpdatePasswordElement } from './update-password-form.element';
import { authenticationService } from '../../../../services';
import { UserProfileContext } from '../../../../store/user-profile.context';

export function UpdatePasswordForm({ postUpdateAction, handleUpdateError }) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [passwordMatches, setPasswordMatches] = useState(false);

    const userProfileContext = useContext(UserProfileContext);

    useEffect(() => {
        setPasswordMatches(
            !isEmpty(password) && !isEmpty(confirmPassword) ? password === confirmPassword : false
        );
    }, [password, confirmPassword]);

    const updatePassword = (event) => {
        event.preventDefault();
        authenticationService
            .updateProfile(
                userProfileContext.userProfile._id,
                userProfileContext.userProfile.externalUserId,
                userProfileContext.userProfile.emailAddress,
                password
            )
            .then(postUpdateAction)
            .catch(handleUpdateError);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const onConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
        const { errors, valid } = validatePassword(event.target.value);
        setIsPasswordValid(valid);
        setErrors(errors);
    };

    const validatePassword = (password) => {
        const lowercase = password.match(/[a-z].*[a-z]+/g);
        const uppercase = password.match(/[A-Z]+/g);
        const digits = password.match(/[\d].*[\d]+/g);
        const length = password.match(/[A-Za-z\d!@#$%^&*_]{8,}/g);
        const errors = [];

        if (!password) {
            errors.push('Password is required');
        }
        if (lowercase === null) {
            errors.push('Password must include at least two lowercase letter');
        }
        if (uppercase === null) {
            errors.push('Password must include at least one uppercase letter');
        }
        if (digits === null) {
            errors.push('Password must include at least two digits from 0 to 9');
        }
        if (length === null) {
            errors.push('Password must include at least 8 characters');
        }

        return {
            errors,
            valid: errors.length < 0
        };
    };

    const handleMouseDownEvent = (event) => {
        event.preventDefault();
    };

    return (
        <UpdatePasswordElement
            isPasswordVisible={isPasswordVisible}
            isPasswordValid={isPasswordValid}
            passwordMatches={passwordMatches}
            password={password}
            confirmPassword={confirmPassword}
            errors={errors}
            handleMouseDownEvent={handleMouseDownEvent}
            togglePasswordVisibility={togglePasswordVisibility}
            onConfirmPasswordChange={onConfirmPasswordChange}
            onPasswordChange={onPasswordChange}
            updatePassword={updatePassword}
        />
    );
}

UpdatePasswordForm.propTypes = {
    postUpdateAction: PropTypes.func,
    handleUpdateError: PropTypes.func
};
