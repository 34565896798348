import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './role-grid.styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { colors } from '../../../../assets/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NegativeActionModal } from '../../../../components/negative-action-modal/negative-action-modal.element';
import { Spinner } from '../../../../components/spinner/spinner';
import { rolesService } from '../../../../services';
import { SuccessModal } from '../../../../components/positive-action-modal/success-modal.element';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import { hasPermission } from '../../../../utils/permission-checker';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../services/toast.service';

export function RoleGridActions(props) {
    const { params, reloadGrid } = props;
    const [isUnlinking, setIsUnlinking] = useState(false);
    const [isLinking, setIslinking] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    const editAction = (id) => {
        history.push('edit-role/' + id);
    };

    const deactivateRoleAction = (event) => {
        event.stopPropagation();
        setIsUnlinking(true);
    };

    const reactivateRoleAction = (event) => {
        event.stopPropagation();
        setIslinking(true);
    };

    const closeUnlinkingModal = () => {
        setIsUnlinking(false);
    };

    const closeLinkingModal = () => {
        setIslinking(false);
    };

    const deactivateRole = () => {
        setIsLoading(true);
        toggleRoleIsActiveFlag(
            params.row,
            `${params.row.roleName} was successfully deactivated`,
            `deactivating ${params.row.roleName}`
        );
        closeUnlinkingModal();
    };

    const reactivateRole = () => {
        setIsLoading(true);
        toggleRoleIsActiveFlag(
            params.row,
            `${params.row.roleName} was successfully reactivated`,
            `reactivating ${params.row.roleName}`
        );
        closeLinkingModal();
    };

    const toggleRoleIsActiveFlag = (row, successMessage, errorMessage) => {
        rolesService
            .setRoleIsActive(row.id, !row.isActive)
            .then(() => {
                setIsLoading(false);
                notifySuccess(successMessage);
                reloadGrid();
            })
            .catch(() => {
                notifyError(`Something went wrong while ${errorMessage}`);
                setIsLoading(false);
            });
    };

    const disabled = !hasPermission(PERMISSIONS.permissions.EditRoles);

    return (
        <div className={classes.iconRoot}>
            <Spinner loading={isLoading} />
            <NegativeActionModal
                open={isUnlinking}
                handleClose={closeUnlinkingModal}
                handleAction={deactivateRole}
                mainText={['You are about to de-activate', `${params.row.roleName}`]}
                subText={['This role will no longer be ', 'available for any user']}
                buttonText1="Cancel"
                buttonText2="De-activate"
            />
            <SuccessModal
                open={isLinking}
                handleClose={closeLinkingModal}
                primaryAction={reactivateRole}
                secondaryAction={closeLinkingModal}
                mainText={['You are about to re-activate', `${params.row.roleName}`]}
                subText={['This role will be ', 'available for any user again']}
                buttonText1="Cancel"
                buttonText2="Re-activate"
            />
            <Tooltip title="Edit role" placement="top">
                <span>
                    <IconButton
                        disabled={disabled}
                        size="small"
                        aria-label="edit"
                        onClick={() => editAction(params.row.id)}
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'pencil-alt']}
                            color={disabled ? colors.greyOne : colors.turquoiseOne}
                        />
                    </IconButton>
                </span>
            </Tooltip>
            {params.row.isActive && (
                <Tooltip title="De-activate role">
                    <span>
                        <IconButton
                            disabled={disabled}
                            size="small"
                            aria-label="delete"
                            onClick={deactivateRoleAction}
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'ban']}
                                color={disabled ? colors.greyOne : colors.hotPinkOne}
                            />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
            {!params.row.isActive && (
                <Tooltip title="Re-activate role">
                    <span>
                        <IconButton
                            disabled={disabled}
                            size="small"
                            aria-label="delete"
                            onClick={reactivateRoleAction}
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'sync']}
                                color={disabled ? colors.greyOne : colors.greenOne}
                            />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
        </div>
    );
}

RoleGridActions.propTypes = {
    id: PropTypes.string,
    params: PropTypes.object,
    handleDeleteClick: PropTypes.func,
    handleEditClick: PropTypes.func,
    reloadGrid: PropTypes.func
};
