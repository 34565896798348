/* eslint-disable react/display-name */
import moment from 'moment';
import React from 'react';
import { MarketingMessagesGridActions } from './marketing-messages-grid-actions';

export const MarketingMessagesColumns = (reloadGridCallback) => {
    return [
        {
            field: 'title',
            editable: false,
            width: 150,
            headerName: 'Title',
            disableColumnMenu: true
        },
        {
            field: 'createdAt',
            editable: false,
            width: 150,
            headerName: 'Date Added',
            headerAlign: 'center',
            align: 'center',
            filterable: true,
            sortable: true,
            disableColumnMenu: true,
            type: Date,
            valueFormatter: (params) => {
                return moment(params.value).format('DD/MM/YYYY');
            }
        },
        {
            field: 'createdBy',
            editable: false,
            width: 150,
            headerName: 'Created By',
            disableColumnMenu: true
        },
        {
            field: 'body',
            editable: false,
            width: 220,
            headerName: 'Body',
            disableColumnMenu: true,
            renderCell: (params) => {
                return <div title={params.value}>{params.value}</div>;
            }
        },
        {
            field: 'linkUrl',
            editable: false,
            width: 150,
            headerName: 'Link URL',
            disableColumnMenu: true
        },
        {
            field: 'linkText',
            editable: true,
            width: 150,
            headerName: 'Link Text',
            disableColumnMenu: true
        },
        {
            field: 'customerCount',
            editable: false,
            width: 150,
            headerName: '# of Customers',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return <p>{params.value}</p>;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => {
                return (
                    <MarketingMessagesGridActions reloadGrid={reloadGridCallback} params={params} />
                );
            },
            sortable: false,
            width: 100,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true
        }
    ];
};
