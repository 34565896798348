import 'react-toastify/dist/ReactToastify.min.css';
import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import {
    CssBaseline,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    Badge,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Avatar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems } from './app-layout-list.element';
import { inAppLogo } from '../../assets/svg/index';
import { useStyles } from './app-layout.styles';
import { useAuth0 } from '@auth0/auth0-react';
import { AppRouting } from '../../routing/app.routing';
import { UserProfileContext } from '../../store/user-profile.context';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from '../error-boundary/error-boundary';
import { AppAlerts } from '../app-alerts/app-alerts.container';

export function AppLayout() {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [profileState, setProfileOpen] = useState(null);

    const { logout } = useAuth0();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const userProfileContext = useContext(UserProfileContext);

    const userName =
        userProfileContext.userProfile.firstName + ' ' + userProfileContext.userProfile.lastName;

    const initials = userName
        .match(/\b(\w)/g)
        .join('')
        .substr(0, 2)
        .toLocaleUpperCase();

    const handleProfileClick = (event) => {
        setProfileOpen(event.currentTarget);
    };

    const handleProfileClose = () => {
        setProfileOpen(null);
    };

    const handleLogout = () => {
        setProfileOpen(null);
        logout({
            returnTo: window.location.origin
        });
    };

    return (
        <div id="app-layout" className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon color="primary" />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        <img
                            src={`data:image/svg+xml;utf8,${inAppLogo}`}
                            alt=""
                            className={classes.titleImage}
                        />
                    </Typography>
                    <IconButton color="primary">
                        <Badge badgeContent={0} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <IconButton
                        color="primary"
                        aria-controls="profile-menu"
                        aria-haspopup="true"
                        onClick={handleProfileClick}
                    >
                        <Avatar className={classes.avatar}>{initials}</Avatar>
                    </IconButton>
                    <Menu
                        id="profile-menu"
                        anchorEl={profileState}
                        keepMounted
                        open={Boolean(profileState)}
                        onClose={handleProfileClose}
                    >
                        <MenuItem onClick={handleProfileClose}>Profile</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon style={{ color: '#fff' }} />
                    </IconButton>
                </div>
                <List>{mainListItems()}</List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <ErrorBoundary>
                        <ToastContainer />
                        <AppRouting />
                        <AppAlerts />
                    </ErrorBoundary>
                </Container>
            </main>
        </div>
    );
}
