import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as EmailValidator from 'email-validator';
import { UserFormElement } from './user-form.element';

export function UserForm(props) {
    const {
        isEdit,
        firstName,
        lastName,
        email,
        active,
        userRoles,
        setFirstName,
        setLastName,
        setEmail,
        setActive,
        setUserRoles,
        loading,
        submitUser,
        onCancel
    } = props;

    useEffect(() => {
        onFormUpdate();
        setIsNameValid(validateNameAndSurname(firstName));
        setIsLastNameValid(validateNameAndSurname(lastName));
        setEmailValid(validateEmail(email));
        setIsRolesValid(userRoles.length > 0);
        onFormUpdate();
    }, [firstName, lastName, email, userRoles]);

    const [isNameValid, setIsNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [isEmailValid, setEmailValid] = useState(false);
    const [isRolesValid, setIsRolesValid] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);

    const setFirstNameForValidation = (e) => {
        setFirstName(extractValue(e));
        setIsNameValid(validateNameAndSurname(extractValue(e)));
        onFormUpdate();
    };

    const setLastNameForValidation = (e) => {
        setLastName(extractValue(e));
        setIsLastNameValid(validateNameAndSurname(extractValue(e)));
        onFormUpdate();
    };

    const setEmailValidation = (e) => {
        setEmail(extractValue(e));
        setEmailValid(validateEmail(extractValue(e)));
        onFormUpdate();
    };

    const onFormUpdate = () => {
        const isValid = isNameValid && isLastNameValid && isEmailValid && isRolesValid;
        setIsValidForm(isValid);
    };

    const validateNameAndSurname = (name) => {
        const hasNumber = /\d/.test(name);
        return name.length >= 3 && !hasNumber;
    };

    const validateEmail = (email) => {
        return EmailValidator.validate(email);
    };

    const extractValue = (e) => {
        return e.target.value;
    };

    return (
        <div>
            <UserFormElement
                firstName={firstName}
                lastName={lastName}
                email={email}
                active={active}
                userRoles={userRoles}
                setUserRoles={setUserRoles}
                setActive={setActive}
                isRolesValid={isRolesValid}
                setIsRolesValid={setIsRolesValid}
                isNameValid={isNameValid}
                isLastNameValid={isLastNameValid}
                isEmailValid={isEmailValid}
                setFirstNameForValidation={setFirstNameForValidation}
                setLastNameForValidation={setLastNameForValidation}
                setEmailValidation={setEmailValidation}
                isValidForm={isValidForm}
                loading={loading}
                isEdit={isEdit}
                submitUser={submitUser}
                onFormUpdate={onFormUpdate}
                onCancel={onCancel}
            />
        </div>
    );
}

UserForm.propTypes = {
    isEdit: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    active: PropTypes.bool,
    userRoles: PropTypes.array,
    setFirstName: PropTypes.func,
    setLastName: PropTypes.func,
    setEmail: PropTypes.func,
    setActive: PropTypes.func,
    setUserRoles: PropTypes.func,
    loading: PropTypes.bool,
    submitUser: PropTypes.func,
    closeErrorModal: PropTypes.func,
    onCancel: PropTypes.func,
    submissionError: PropTypes.bool
};
