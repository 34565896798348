import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    },
    mr: {
        marginRight: 20
    }
}));

export const styles = {
    buttonBox: { padding: 20, paddingRight: 0, paddingLeft: 0 },
    tittleBox: { padding: 20, paddingLeft: 0 }
};

export const successModal = {
    btnContainer: {
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    iconStyle: {
        alignSelf: 'center',
        marginBottom: 10
    },
    centerSelf: {
        alignSelf: 'center'
    },
    textMessage: {
        color: 'GrayText',
        alignSelf: 'center'
    },
    btnWidth: {
        minWidth: '70%'
    },
    dialog: {
        paddingTop: 15
    },
    cancelIcon: {
        alignSelf: 'flex-end',
        margin: 0,
        background: 'transparent'
    }
};
