import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MobileNumberInputElement } from './mobile-number-input.element';

export function MobileNumberInput({ number, setNumber, isValid, setIsValid, setSanitizedNumber }) {
    const [country, setCountry] = useState('gb');
    const [prefix, setPrefix] = useState('+44');

    useEffect(() => {
        setSanitizedNumber(cleanNumber());
    }, [number]);

    const onCountryChange = (event) => {
        setCountry(event.target.value);
        if (event.target.value === 'gb') {
            setPrefix('+44');
        } else {
            setPrefix('+27');
        }
    };

    const validatePhone = (phoneNumber) => {
        let regexPhone;
        if (country === 'gb') {
            regexPhone = /^(([0-0]{1}[1-9]{1}[0-9]{9})|[1-9]{1}[0-9]{9})?$/;
        } else if (country === 'za') {
            regexPhone = /^(([0-0]{1}[1-9]{1}[0-9]{8})|[1-9]{1}[0-9]{8})?$/;
        }

        if (!regexPhone.test(phoneNumber)) {
            return false;
        } else {
            return true;
        }
    };

    const cleanNumber = () => {
        const phoneNumber = prefix + number.replace(/^0+/, '');
        return phoneNumber;
    };

    const onNumberChange = (event) => {
        setNumber(event.target.value);
        setIsValid(validatePhone(event.target.value));
        setSanitizedNumber(cleanNumber());
    };

    return (
        <MobileNumberInputElement
            onNumberChange={onNumberChange}
            onCountryChange={onCountryChange}
            prefix={prefix}
            isValid={isValid}
            number={number}
            country={country}
        />
    );
}

MobileNumberInput.propTypes = {
    isValid: PropTypes.bool,
    number: PropTypes.string,

    setNumber: PropTypes.func,
    setIsValid: PropTypes.func,
    setSanitizedNumber: PropTypes.func,
    onFormUpdate: PropTypes.func
};
