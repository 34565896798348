import moment from 'moment';

export const SelectCustomerColumns = (reloadGridCallback) => {
    return [
        { field: 'id', editable: false, hide: true, filterable: false },
        { field: 'emailAddressVerified', editable: false, hide: true, filterable: false },
        { field: 'externalUserId', editable: false, hide: true, filterable: false },
        { field: 'couchUserAccessKey', editable: false, hide: true, filterable: false },
        { field: 'updatedAt', editable: false, hide: true, filterable: false },
        { field: 'termsAccepted', editable: false, hide: true, filterable: false },
        { field: 'metadata', editable: false, hide: true, filterable: false },
        { field: 'provisioned', editable: false, hide: true, filterable: false },
        { field: 'deviceIds', editable: false, hide: true, filterable: false },
        { field: 'accountState', editable: false, hide: true, filterable: false },
        {
            field: 'nigelId',
            editable: false,
            width: 150,
            headerName: 'Nigel ID',
            disableColumnMenu: true,
            filterable: false,
            sortable: false
        },
        {
            field: 'customerId',
            editable: false,
            width: 150,
            headerName: 'Customer ID',
            disableColumnMenu: true
        },
        {
            field: 'firstName',
            editable: false,
            width: 150,
            headerName: 'First Name',
            disableColumnMenu: true
        },
        {
            field: 'lastName',
            editable: true,
            width: 150,
            headerName: 'Last Name',
            disableColumnMenu: true
        },
        {
            field: 'emailAddress',
            editable: false,
            disableColumnMenu: true,
            width: 150,
            headerName: 'Email'
        },
        {
            field: 'mobileNumber',
            editable: false,
            width: 150,
            headerName: 'Mobile Number',
            disableColumnMenu: true,
            sortable: false,
            filterable: true
        },
        {
            field: 'createdAt',
            editable: false,
            minWidth: 150,
            headerName: 'Date Added',
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            type: Date,
            valueFormatter: (params) => {
                return moment(params.value).format('DD/MM/YYYY');
            }
        }
    ];
};
