import React, { useState, useContext } from 'react';
import { Grid, Typography, Button, Container } from '@material-ui/core';
import { AddMarketingMessageForm } from '../../../components/marketing-messages/add-marketing-message-form.container';
import { MarketingMessagePhonePreview } from '../../../components/marketing-messages/marketing-message-phone-preview.element';
import { Spinner } from '../../../components/spinner/spinner';
import { SelectCustomersModal } from '../../../components/select-customers-modal/select-customers-modal.element';
import { styles, useStyles } from './create-marketing-message.styles';
import { marketingMessageService } from '../../../services';
import { UserProfileContext } from '../../../store/user-profile.context';
import { useHistory } from 'react-router';
import { notifyError, notifySuccess } from '../../../services/toast.service';

export function CreateMarketingMessagePage() {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [url, setURL] = useState('');
    const [urlText, setURLTextLabel] = useState('');
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [openCustomersModal, setOpenCustomersModal] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const userProfileContext = useContext(UserProfileContext);
    const partnerId = userProfileContext.userProfile.partnerId;

    const processFile = (f) => {
        if (f === null) {
            setImage('');
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = function () {
            setImage(reader.result);
        };
        reader.onerror = function () {
            setImage('');
        };
    };

    const submitMessage = async (customers) => {
        setLoading(true);
        const userName = `${userProfileContext.userProfile.firstName} ${userProfileContext.userProfile.lastName}`;

        const message = {
            title,
            body,
            linkUrl: url,
            linkText: urlText,
            customers,
            partnerId,
            fileData: image,
            createdBy: userName
        };

        try {
            marketingMessageService
                .addMarketingMessage(message)
                .then(() => {
                    setLoading(false);
                    notifySuccess('Marketing message sent successfully');
                    successCallBack();
                })
                .catch(() => {
                    setLoading(false);
                    notifyError('Unable to upload marketing message');
                });
        } catch (err) {
            notifyError(`Something went wrong`);
            setLoading(false);
        }
    };

    const successCallBack = () => {
        history.push('view-marketing-message');
    };

    const getSelectedCustomers = (selectedCustomers) => {
        if (selectedCustomers != null && selectedCustomers.length > 0) {
            submitMessage(selectedCustomers);
        } else {
            notifyError('Please select at least one customer');
        }
    };

    return (
        <Container>
            <Spinner loading={loading} />
            <SelectCustomersModal
                openCustomersModal={openCustomersModal}
                setOpenCustomersModal={setOpenCustomersModal}
                getSelectedCustomers={getSelectedCustomers}
            ></SelectCustomersModal>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <div className={classes.root}>
                    <div style={styles.tittleBox}>
                        <Typography variant="h4" component="span">
                            Marketing Message
                        </Typography>
                    </div>
                    <div style={styles.buttonBox}>
                        <Button
                            size="large"
                            variant="contained"
                            color="secondary"
                            disabled={!isValidForm}
                            onClick={() => {
                                setOpenCustomersModal(true);
                            }}
                        >
                            Select Customers
                        </Button>
                    </div>
                </div>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <AddMarketingMessageForm
                        title={title}
                        body={body}
                        url={url}
                        urlText={urlText}
                        setTitle={setTitle}
                        setBody={setBody}
                        setURL={setURL}
                        setURLTextLabel={setURLTextLabel}
                        processFile={processFile}
                        setIsValidForm={setIsValidForm}
                        submissionError={submissionError}
                        setSubmissionError={setSubmissionError}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <MarketingMessagePhonePreview
                        title={title}
                        body={body}
                        url={url}
                        image={image}
                        urlText={urlText === '' ? url : urlText}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
