import React, { useRef } from 'react';
import { Typography, Button, Box, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { colors } from '../../assets/styles/colors';
import { styles } from './file-picker.styles';
import PropTypes from 'prop-types';

export function FilePicker(props) {
    const filePicker = useRef();
    const { onDrop } = props;
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop(item) {
                if (onDrop) {
                    onDrop(item.files[0]);
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }),
        [props]
    );

    const onChangeFile = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            onDrop(file);
        }
        e.target.value = null;
    };

    return (
        <div ref={drop} style={styles.container}>
            {canDrop ? (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: 20
                        }}
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FontAwesomeIcon icon="upload" size="5x" color={colors.navyOne} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant="h6">Drop File Here</Typography>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <div style={{ width: '100%', height: '100%' }}>
                    <input
                        type="file"
                        id="file"
                        ref={filePicker}
                        style={{ display: 'none' }}
                        onChange={onChangeFile}
                    />
                    <Button
                        variant="text"
                        style={{ width: '100%', height: '100%', borderRadius: 0 }}
                        onClick={() => {
                            filePicker.current.click();
                        }}
                    >
                        <Box sx={{ display: 'flex', p: 1 }}>
                            <Box sx={{ p: 1, width: '60%' }}>
                                <FontAwesomeIcon icon="upload" size="5x" color={colors.navyOne} />
                            </Box>
                            <Box sx={{ p: 1, flexShrink: 1 }}>
                                <Typography variant="h5" style={styles.text}>
                                    Drop or click to upload file
                                </Typography>
                            </Box>
                        </Box>
                    </Button>
                </div>
            )}
        </div>
    );
}

FilePicker.propTypes = {
    onDrop: PropTypes.func
};
