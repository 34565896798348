import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AddMarketingMessageElement } from './add-marketing-message-form.element';
import { validateURL } from '../../utils/validate-url';

export function AddMarketingMessageForm(props) {
    const {
        title,
        body,
        url,
        urlText,
        setTitle,
        setBody,
        setURL,
        setURLTextLabel,
        processFile,
        setIsValidForm,
        submissionError,
        setSubmissionError,
        editable
    } = props;

    const [titleError, setTitleError] = useState('');
    const [bodyError, setBodyError] = useState('');
    const [urlError, setUrlError] = useState('');
    const isEditable = editable !== undefined ? editable : true;

    useEffect(() => {}, [title, body, url, urlText]);

    const setTitleForValidation = (e) => {
        setTitle(extractValue(e));
        setTitleError(validateTitle(extractValue(e)));
        onFormUpdate();
    };

    const setBodyForValidation = (e) => {
        setBody(extractValue(e));
        setBodyError(validateBody(extractValue(e)));
        onFormUpdate();
    };

    const setURLValidation = (e) => {
        setURL(extractValue(e));
        setUrlError(validateURL(extractValue(e)));
        onFormUpdate();
    };

    const setURLText = (e) => {
        setURLTextLabel(extractValue(e));
        onFormUpdate();
    };

    const onFormUpdate = () => {
        const isValid =
            titleError.length === 0 &&
            title.length > 0 &&
            bodyError.length === 0 &&
            body.length > 0 &&
            urlError.length === 0;
        setIsValidForm(isValid);
    };

    const validateTitle = (title) => {
        if (title.length === 0) {
            return 'Title is required';
        }
        if (title.length < 3) {
            return 'Title needs to be at least 3 characters';
        }
        if (title.length > 50) {
            return 'Title cannot be greater than 50 characters';
        }
        return '';
    };

    const validateBody = (body) => {
        if (body.length === 0) {
            return 'Body is required';
        }
        if (body.length < 3) {
            return 'Body needs to be at least 3 characters';
        }
        if (body.length > 500) {
            return 'Body cannot be greater than 500 characters';
        }
        return '';
    };

    const extractValue = (e) => {
        return e.target.value;
    };

    const closeErrorModal = () => {
        setSubmissionError(false);
    };

    return (
        <div style={{ height: '100%' }}>
            <AddMarketingMessageElement
                isEditable={isEditable}
                title={title}
                body={body}
                url={url}
                urlText={urlText}
                titleError={titleError}
                bodyError={bodyError}
                urlError={urlError}
                submissionError={submissionError}
                setTitleForValidation={setTitleForValidation}
                setBodyForValidation={setBodyForValidation}
                setURLValidation={setURLValidation}
                setURLText={setURLText}
                onFormUpdate={onFormUpdate}
                closeErrorModal={closeErrorModal}
                processFile={processFile}
            />
        </div>
    );
}

AddMarketingMessageForm.propTypes = {
    editable: PropTypes.bool,
    title: PropTypes.string,
    body: PropTypes.string,
    url: PropTypes.string,
    urlText: PropTypes.string,
    setTitle: PropTypes.func,
    setBody: PropTypes.func,
    setURL: PropTypes.func,
    setURLTextLabel: PropTypes.func,
    openSuccessModel: PropTypes.func,
    processFile: PropTypes.func,
    setIsValidForm: PropTypes.func,
    submissionError: PropTypes.bool,
    setSubmissionError: PropTypes.func
};
