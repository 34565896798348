import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import { styles } from './role-form.styles';
import { Spinner } from '../../../../components/spinner/spinner';
import { isEmpty } from 'lodash';
import { RolePermissions } from './role-permissions.element';

export function RoleFormElement(props) {
    const {
        roleName,
        isRoleNameValid,
        setRoleNameForValidation,
        roleDescription,
        isRoleDescriptionValid,
        setRoleDescriptionForValidation,
        active,
        setActive,
        permissions,
        setPermissions,
        isPermissionsValid,
        setIsPermissionsValid,
        isValidForm,
        loading,
        isEdit,
        submitRole,
        onCancel
    } = props;

    return (
        <div style={{ paddingBottom: 80 }}>
            <Spinner loading={loading} />
            <Typography
                align="center"
                style={{ paddingBottom: 30, marginLeft: 90 }}
                variant="h4"
                component="h4"
            >
                {isEdit ? 'Edit Role' : 'Add a new role'}
            </Typography>
            <Grid container spacing={0}>
                <Grid item xl={3} lg={4} md={3} sm={2} xs={12}></Grid>
                <Grid
                    item
                    xl={7}
                    lg={4}
                    md={6}
                    sm={8}
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                    container
                    direction="column"
                    spacing={2}
                    style={styles.container}
                >
                    <form autoComplete="off">
                        <Box display="flex" justifyContent="center" m={2} p={3}>
                            <Box>
                                <Typography style={styles.header} variant="body1" component="h6">
                                    Role Details
                                </Typography>
                                <Box display="flex" flexDirection="column">
                                    <TextField
                                        value={roleName}
                                        onChange={setRoleNameForValidation}
                                        label="Role Name"
                                        error={!isRoleNameValid && !isEmpty(roleName)}
                                        required={true}
                                        helperText={
                                            !isRoleNameValid && !isEmpty(roleName)
                                                ? 'Please enter a valid role name.'
                                                : ''
                                        }
                                        style={styles.textField}
                                    />
                                    <TextField
                                        value={roleDescription}
                                        onChange={setRoleDescriptionForValidation}
                                        label="Role Description"
                                        error={!isRoleDescriptionValid && !isEmpty(roleDescription)}
                                        required={true}
                                        helperText={
                                            !isRoleDescriptionValid && !isEmpty(roleDescription)
                                                ? 'Please enter a valid role description.'
                                                : ''
                                        }
                                        style={styles.textField}
                                    />
                                </Box>

                                <Typography
                                    style={{ marginTop: 20 }}
                                    variant="body1"
                                    component="h6"
                                >
                                    Role Permissions
                                </Typography>
                                <RolePermissions
                                    isPermissionsValid={isPermissionsValid}
                                    setIsPermissionsValid={setIsPermissionsValid}
                                    setPermissions={setPermissions}
                                    permissions={permissions}
                                />
                                <Box display="flex" justifyContent="center" p={1}>
                                    <FormControlLabel
                                        style={styles.noMargin}
                                        control={
                                            <Switch
                                                checked={active}
                                                name="active-role"
                                                color="primary"
                                                disabled={!isEdit}
                                                onChange={(event) => {
                                                    setActive(event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Active role"
                                        labelPlacement="start"
                                    />
                                </Box>
                                <Box display="flex" justifyContent="center" p={1}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        style={styles.btnCancel}
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!isValidForm}
                                        onClick={submitRole}
                                        styles={styles.btn}
                                    >
                                        {isEdit ? 'Update' : 'Create'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Grid>
                <Grid item xl={2} lg={4} md={3} sm={2} xs={12}></Grid>
            </Grid>
        </div>
    );
}

RoleFormElement.propTypes = {
    roleName: PropTypes.string,
    isRoleNameValid: PropTypes.bool,
    setRoleNameForValidation: PropTypes.func,
    roleDescription: PropTypes.string,
    isRoleDescriptionValid: PropTypes.bool,
    setRoleDescriptionForValidation: PropTypes.func,
    active: PropTypes.bool,
    setActive: PropTypes.func,
    permissions: PropTypes.array,
    setPermissions: PropTypes.func,
    isPermissionsValid: PropTypes.bool,
    setIsPermissionsValid: PropTypes.func,
    isValidForm: PropTypes.bool,
    loading: PropTypes.bool,
    isEdit: PropTypes.bool,
    submitRole: PropTypes.func,
    onCancel: PropTypes.func
};
