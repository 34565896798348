import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IconButton, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styles } from './select-customers-modal.styles';
import { SelectCustomerGrid } from '../select-customer-grid/select-customer-grid.container';

export function SelectCustomersModal({
    openCustomersModal,
    setOpenCustomersModal,
    getSelectedCustomers
}) {
    const [selected, setSelected] = useState([]);

    const closeModal = () => {
        setOpenCustomersModal(false);
    };

    const onSelectedChanged = (ids) => {
        setSelected(ids);
    };

    return (
        <div>
            <Dialog
                maxWidth={'lg'}
                fullWidth={true}
                aria-labelledby="user-add-successful"
                open={openCustomersModal}
            >
                <IconButton onClick={closeModal} style={styles.cancelIcon}>
                    <CloseIcon fontSize="small" />
                </IconButton>
                <MuiDialogContent style={styles.dialog}>
                    <Typography variant="h4" style={{ margin: 10 }}>
                        Select Customer(s)
                    </Typography>
                    <SelectCustomerGrid onSelectedChanged={onSelectedChanged} />
                </MuiDialogContent>
                <MuiDialogActions style={styles.btnContainer}>
                    <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            getSelectedCustomers(selected);
                            closeModal();
                        }}
                    >
                        Continue
                    </Button>
                </MuiDialogActions>
            </Dialog>
        </div>
    );
}

SelectCustomersModal.propTypes = {
    openCustomersModal: PropTypes.bool,
    setOpenCustomersModal: PropTypes.func,
    getSelectedCustomers: PropTypes.func
};
