import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { successModal as styles } from './negative-action-modal.styles';

export function NegativeActionModal(props) {
    const { handleClose, open, handleAction, mainText, subText, buttonText1, buttonText2 } = props;

    return (
        <Dialog
            container={() => document.getElementById('app-layout')}
            aria-labelledby="negative-action"
            open={open}
            style={{ padding: 20 }}
        >
            <IconButton onClick={handleClose} style={styles.cancelIcon}>
                <CloseIcon fontSize="small" />
            </IconButton>
            <MuiDialogContent style={styles.dialog}>
                <Grid spacing={3} direction="column" container>
                    <Typography style={styles.centerSelf} variant="h6">
                        {mainText[0] ?? ''}
                    </Typography>
                    <Typography style={styles.centerSelf} variant="h6">
                        {mainText[1] ?? ''}
                    </Typography>

                    <Typography
                        style={{ ...styles.textMessage, ...styles.dialog }}
                        variant="subtitle1"
                        component="p"
                    >
                        {subText[0] ?? ''}
                    </Typography>
                    <Typography style={styles.textMessage} variant="subtitle1" component="p">
                        {subText[1] ?? ''}
                    </Typography>
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions style={styles.btnContainer}>
                <Button
                    style={{ minWidth: 120 }}
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                >
                    {buttonText1}
                </Button>
                <Button
                    style={{
                        color: colors.whiteOne,
                        backgroundColor: colors.redOne,
                        minWidth: 120
                    }}
                    size="large"
                    variant="contained"
                    onClick={handleAction}
                >
                    {buttonText2}
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}

NegativeActionModal.propTypes = {
    open: PropTypes.bool,
    handleAction: PropTypes.func,
    handleClose: PropTypes.func,
    mainText: PropTypes.array,
    subText: PropTypes.array,
    buttonText1: PropTypes.string,
    buttonText2: PropTypes.string
};
