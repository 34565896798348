import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControl, FormHelperText, Grid } from '@material-ui/core';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';

export function RolePermissions(props) {
    const { isPermissionsValid, setIsPermissionsValid, setPermissions, permissions } = props;
    const [rolePermissions, setRolePermissions] = useState([]);

    useEffect(() => {
        getPermissions();
    }, []);

    const getPermissions = () => {
        const permissionsArr = Object.keys(PERMISSIONS.permissions);
        setRolePermissions(permissionsArr);
    };

    const handleChange = (event, permissionId) => {
        event.stopPropagation();
        const temp = [...permissions];
        if (event.target.checked) {
            temp.push(permissionId);
        } else {
            const index = temp.indexOf(permissionId);
            if (index > -1) {
                temp.splice(index, 1);
            }
        }
        setPermissions(temp);
        setIsPermissionsValid(temp.length > 0);
    };

    const isChecked = (permissionId) => {
        return permissions && permissions.includes(permissionId);
    };

    return (
        <>
            <Grid container spacing={0}>
                {rolePermissions.map((permission) => (
                    <Grid item xs={permission.length > 15 ? 12 : 3} key={permission}>
                        <label>
                            <Checkbox
                                name={permission}
                                checked={isChecked(permission)}
                                onChange={(event) => handleChange(event, permission.toString())}
                            />
                            {permission}
                        </label>
                        <br />
                    </Grid>
                ))}
            </Grid>
            <Box display="flex" justifyContent="center" p={1}>
                <FormControl required error={!isPermissionsValid} component="fieldset">
                    {!isPermissionsValid && (
                        <FormHelperText>Please select at least one permission.</FormHelperText>
                    )}
                </FormControl>
            </Box>
        </>
    );
}

RolePermissions.propTypes = {
    isPermissionsValid: PropTypes.bool,
    setIsPermissionsValid: PropTypes.func,
    setPermissions: PropTypes.func,
    permissions: PropTypes.array
};
