import React from 'react';
import { Container } from '@material-ui/core';
import { styles } from './marketing-message-phone-preview.styles';
import PropTypes from 'prop-types';
import { inAppLogo } from '../../assets/svg/index';

export function MarketingMessagePhonePreview(props) {
    const { title, body, url, urlText, image } = props;

    return (
        <Container style={styles.container}>
            <div style={styles.phone}>
                <div style={styles.phoneHeader}>
                    <div style={styles.logo}>
                        <img src={`data:image/svg+xml;utf8,${inAppLogo}`} alt="" />
                    </div>
                </div>
                <div style={styles.content}>
                    <div style={styles.title}>{title}</div>
                    <br />
                    <img src={`${image}`} alt="" style={styles.image} />
                    <br />
                    <div style={styles.body}>{body}</div>
                    <br />
                    {url && (
                        <a style={styles.url} href={url}>
                            {urlText}
                        </a>
                    )}
                    <br />
                    <div style={styles.btnContinueHolder}>
                        <p style={styles.btnContinue}>Dismiss</p>
                    </div>
                    <br />
                </div>
            </div>
        </Container>
    );
}

MarketingMessagePhonePreview.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    url: PropTypes.string,
    urlText: PropTypes.string,
    image: PropTypes.string
};
