import { httpService } from './http.service';

async function getPartnerProfile(partnerId) {
    const path = `partner/${partnerId}`;
    const response = await httpService.get(path);
    return response;
}

export const partnerProfileService = {
    getPartnerProfile
};
