import { DocumentGridActions } from './document-grid-actions';
import React from 'react';
import moment from 'moment';
import { makeFileStatusReadable } from '../../../../utils/file-helper';

export const DocumentColumns = (reloadGridCallback) => {
    const viewCustomers = (id) => {};

    return [
        { field: 'id', editable: false, hide: true, filterable: false },
        { field: 'emailAddressVerified', editable: false, hide: true, filterable: false },
        { field: 'partnerId', editable: false, hide: true, filterable: false },
        { field: 'note', editable: false, hide: true, filterable: false },
        { field: 'reminder', editable: false, hide: true, filterable: false },
        { field: 'isSmartUpload', editable: false, hide: true, filterable: false },
        { field: 'size', editable: false, hide: true, filterable: false },
        { field: 'type', editable: false, hide: true, filterable: false },
        { field: 'tags', editable: false, hide: true, filterable: false },
        { field: 'blobFileId', editable: false, hide: true, filterable: false },
        { field: 'documentSpaceIds', editable: false, hide: true, filterable: false },
        { field: 'privateMetadataId', editable: false, hide: true, filterable: false },
        { field: 'thumbnail', editable: false, hide: true, filterable: false },
        { field: 'internalOcr', editable: false, hide: true, filterable: false },
        {
            field: 'name',
            editable: false,
            width: 370,
            headerName: 'Document Name',
            disableColumnMenu: true
        },
        {
            field: 'sharedBy',
            editable: false,
            width: 150,
            headerName: 'Shared By',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => {
                return `${params.value.firstName} ${params.value.lastName}`;
            }
        },
        {
            field: 'createdAt',
            editable: false,
            width: 150,
            headerName: 'Shared Date',
            disableColumnMenu: true,
            type: Date,
            sortable: true,
            valueFormatter: (params) => {
                return moment(params.value).format('DD/MM/YYYY');
            }
        },
        {
            field: 'customerCount',
            editable: false,
            width: 150,
            headerName: '# of Customers',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <a
                        onClick={() => {
                            viewCustomers(params.id);
                        }}
                    >
                        {params.value}
                    </a>
                );
            }
        },
        {
            field: 'status',
            editable: false,
            width: 225,
            headerName: 'Status',
            disableColumnMenu: true,
            type: 'string',
            valueFormatter: (params) => {
                return makeFileStatusReadable(params.value);
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => {
                return <DocumentGridActions reloadGrid={reloadGridCallback} params={params} />;
            },
            sortable: false,
            width: 100,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true
        }
    ];
};
