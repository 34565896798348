import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { rolesService } from '../../../../services';
import { UserProfileContext } from '../../../../store/user-profile.context';
import { Checkbox, FormControl, FormHelperText, Grid } from '@material-ui/core';

export function UserRoles(props) {
    const { isRolesValid, setIsRolesValid, onFormUpdate, setUserRoles, userRoles } = props;
    const userProfileContext = useContext(UserProfileContext);
    const partnerId = userProfileContext.userProfile.partnerId;
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = () => {
        rolesService.getPartnerRoles(partnerId).then((r) => {
            setRoles(r);
        });
    };

    const handleChange = (event, roleId) => {
        event.stopPropagation();
        const temp = [...userRoles];
        if (event.target.checked) {
            temp.push(roleId);
        } else {
            const index = temp.indexOf(roleId);
            if (index > -1) {
                temp.splice(index, 1);
            }
        }
        setUserRoles(temp);
        setIsRolesValid(temp.length > 0);
        onFormUpdate();
    };

    const isChecked = (roleId) => {
        return userRoles && userRoles.includes(roleId);
    };

    return (
        <>
            <Grid container spacing={0}>
                {roles.map((item) => (
                    <Grid item xs={item.roleName.length > 15 ? 12 : 6} key={item.roleId}>
                        <label>
                            <Checkbox
                                name={item.roleName}
                                checked={isChecked(item.roleId)}
                                onChange={(event) => handleChange(event, item.roleId.toString())}
                            />
                            {item.roleName}
                        </label>
                        <br />
                    </Grid>
                ))}
            </Grid>

            <FormControl required error={!isRolesValid} component="fieldset">
                {!isRolesValid && <FormHelperText>Please select at least one role.</FormHelperText>}
            </FormControl>
        </>
    );
}

UserRoles.propTypes = {
    isRolesValid: PropTypes.bool,
    setIsRolesValid: PropTypes.func,
    onFormUpdate: PropTypes.func,
    setUserRoles: PropTypes.func,
    userRoles: PropTypes.array
};
