import React from 'react';
import { AddRoleForm } from './add-role-form/add-role-form.container';

export function AddRolePage() {
    return (
        <>
            <AddRoleForm />
        </>
    );
}
