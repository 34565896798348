import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    TextField,
    FormGroup,
    FormHelperText,
    FormControl,
    IconButton,
    Button,
    InputAdornment
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { map, isEmpty } from 'lodash';
import { styles } from './update-password-form.styles';

export function UpdatePasswordElement(props) {
    const {
        isPasswordVisible,
        togglePasswordVisibility,
        handleMouseDownEvent,
        passwordMatches,
        errors,
        isPasswordValid,
        onPasswordChange,
        onConfirmPasswordChange,
        password,
        confirmPassword,
        updatePassword
    } = props;

    const errorText = (error) => {
        return <FormHelperText key={error}>{`\u2022 ${error}`}</FormHelperText>;
    };

    return (
        <Box p={5}>
            <form>
                <Typography style={styles.header} variant="h6" component="h5">
                    Update password
                </Typography>
                <Box display="flex" flexDirection="column">
                    <FormControl
                        required
                        error={!passwordMatches || !isPasswordValid}
                        component="fieldset"
                    >
                        <FormGroup>
                            <TextField
                                label="Password"
                                type={isPasswordVisible ? 'text' : 'password'}
                                variant="standard"
                                onChange={onPasswordChange}
                                value={password}
                                style={styles.textField}
                            />
                            <TextField
                                label="Confirm password"
                                variant="standard"
                                type={isPasswordVisible ? 'text' : 'password'}
                                onChange={onConfirmPasswordChange}
                                value={confirmPassword}
                                style={styles.textField}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                onMouseDown={handleMouseDownEvent}
                                            >
                                                {isPasswordVisible ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormGroup>

                        {!passwordMatches && !isEmpty(confirmPassword) && (
                            <FormHelperText>{`\u2022 Passwords do not match`}</FormHelperText>
                        )}

                        {!isPasswordValid && !isEmpty(password) && map(errors, errorText)}
                    </FormControl>
                    <Box display="flex" justifyContent="center" p={1} mt={5}>
                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            color="secondary"
                            disabled={!passwordMatches && !isPasswordValid}
                            onClick={updatePassword}
                            styles={{ minWidth: '80%' }}
                        >
                            Update Password
                        </Button>
                    </Box>
                </Box>
            </form>
        </Box>
    );
}

UpdatePasswordElement.propTypes = {
    isPasswordVisible: PropTypes.bool,
    passwordMatches: PropTypes.bool,
    isPasswordValid: PropTypes.bool,

    password: PropTypes.string,
    confirmPassword: PropTypes.string,

    errors: PropTypes.array,

    togglePasswordVisibility: PropTypes.func,
    handleMouseDownEvent: PropTypes.func,
    onPasswordChange: PropTypes.func,
    onConfirmPasswordChange: PropTypes.func,
    updatePassword: PropTypes.func
};
