import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5)
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`
        }
    },
    hideRightSeparator: {
        '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden'
        },
        '& .MuiDataGrid-row.Mui-odd': {
            backgroundColor: '#f3f3f3'
        }
    },
    iconRoot: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    textPrimary: {
        color: theme.palette.text.primary
    }
}));

export const styles = {
    filterSpacing: {
        marginTop: 10,
        marginRight: 10
    },
    gridHeader: {
        margin: 10
    },
    container: {
        width: '100%',
        height: '80%',
        display: 'flex'
    },
    gridHeight: {
        height: '80%',
        display: 'flex'
    },
    whiteSpace: {
        height: 100
    }
};
