import { httpService } from './http.service';

async function createEvent(data) {
    const url = 'events';
    const response = await httpService.post(url, data);
    return response.data;
}

export const eventService = {
    createEvent
};
