export const styles = {
    dialog: {
        paddingTop: 0
    },
    cancelIcon: {
        alignSelf: 'flex-end',
        margin: 0,
        background: 'transparent'
    }
};
