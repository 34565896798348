import { customerProfileService } from '../../../services';
import { notifySuccess } from '../../../services/toast.service';
import * as XLSX from 'xlsx';
import { mapKeys } from 'lodash';

export const downloadExcelFile = () => {
    const link = document.createElement('a');
    link.download = 'add_customers.xlsx';
    link.href = window.location.origin + '/add_customers.xlsx';
    link.click();
};

export const processFile = ({
    file,
    userProfileContext,
    setLoading,
    setSubmissionError,
    setErrorReportType,
    setErrorReportData,
    history
}) => {
    setLoading(true);

    if (!validFileExtension(file.type)) {
        setSubmissionError(true);
        setErrorReportType('file_type');
        setLoading(false);
        return;
    }

    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
        try {
            const json = file.name.toUpperCase().endsWith('.CSV')
                ? getJsonFromCSV(reader.result)
                : getJsonFromXSLX(e.target.result);

            if (!validJsonHeaderRow(json)) {
                setSubmissionError(true);
                setErrorReportType('invalid_records');
                setLoading(false);
                return;
            }

            const result = json.map((obj) => {
                return {
                    ...obj,
                    partnerId: userProfileContext.userProfile.partnerId,
                    mobileNumber: `+${obj.mobileNumber}`
                };
            });
            uploadCustomers({
                customers: result,
                setErrorReportData,
                setSubmissionError,
                setErrorReportType,
                setLoading,
                history
            });
        } catch (err) {
            alert(err);
            setLoading(false);
        }
    };
    reader.onerror = () => {
        alert(reader.error); // TODO: do something
    };
};

const validFileExtension = (fileType) => {
    switch (fileType) {
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'text/csv':
            return true;
        default:
            return false;
    }
};

const validJsonHeaderRow = (data) => {
    const finalIndex = data.length > 10 ? 10 : data.length;

    const resultObject = {};

    for (let i = 0; i < finalIndex; i++) {
        const row = data[parseInt(i)];

        if (row.customerId) {
            resultObject.customerId = true;
        }

        if (row.firstName) {
            resultObject.firstName = true;
        }

        if (row.lastName) {
            resultObject.lastName = true;
        }

        if (row.emailAddress) {
            resultObject.emailAddress = true;
        }

        if (row.countryCode) {
            resultObject.countryCode = true;
        }

        if (row.mobileNumber) {
            resultObject.mobileNumber = true;
        }
    }

    if (
        !resultObject.customerId ||
        !resultObject.firstName ||
        !resultObject.lastName ||
        !resultObject.emailAddress ||
        !resultObject.countryCode ||
        !resultObject.mobileNumber
    ) {
        return false;
    }

    return true;
};

const getJsonFromXSLX = (data) => {
    const workbook = XLSX.read(data, {
        type: 'binary'
    });
    return XLSX.utils
        .sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]])
        .map((row) => mapKeys(row, (value, key) => key.trim()));
};

const getJsonFromCSV = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    return XLSX.utils
        .sheet_to_json(workbook.Sheets.Sheet1)
        .map((row) => mapKeys(row, (value, key) => key.trim()));
};

const uploadCustomers = async ({
    customers,
    history,
    setErrorReportData,
    setSubmissionError,
    setErrorReportType,
    setLoading
}) => {
    try {
        const res = await customerProfileService.addCustomers(customers);
        if (res.data && res.data.code === 400) {
            setErrorReportData(res.data.data);
            setSubmissionError(true);
            setErrorReportType('error_report');
        } else {
            notifySuccess(
                `${res.data.numberOfCustomers} customers added successfully. The customer(s) have been invited to download the app.`
            );
            goToCustomersPage(history);
        }
    } catch (err) {
        setSubmissionError(true);
        setErrorReportType('');
    }
    setLoading(false);
};

export const downloadErrorReport = ({ errorReportData, setSubmissionError }) => {
    const csv = XLSX.utils.json_to_sheet(errorReportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, csv, 'Error Report');
    XLSX.writeFile(wb, 'error_report.xlsx');
    setSubmissionError(false);
};

const goToCustomersPage = (history) => {
    history.push('customers');
};
