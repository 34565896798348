import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import { styles } from './user-form.styles';
import { Spinner } from '../../../../components/spinner/spinner';
import { UserRoles } from './user-role.element';
import { isEmpty } from 'lodash';

export function UserFormElement(props) {
    const {
        firstName,
        lastName,
        email,
        active,
        userRoles,
        setActive,
        setUserRoles,
        isRolesValid,
        setIsRolesValid,
        isNameValid,
        isLastNameValid,
        isEmailValid,
        setFirstNameForValidation,
        setLastNameForValidation,
        setEmailValidation,
        isValidForm,
        loading,
        isEdit,
        submitUser,
        onFormUpdate,
        onCancel
    } = props;

    return (
        <div style={{ paddingBottom: 80 }}>
            <Spinner loading={loading} />
            <Typography align="center" style={{ paddingBottom: 30 }} variant="h4" component="h4">
                {isEdit ? 'Edit User' : 'Add a new user'}
            </Typography>
            <Grid container spacing={0}>
                <Grid item xl={4} lg={4} md={3} sm={2} xs={12}></Grid>
                <Grid
                    item
                    xl={4}
                    lg={4}
                    md={6}
                    sm={8}
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                    container
                    direction="column"
                    spacing={2}
                    style={styles.container}
                >
                    <form autoComplete="off">
                        <Box display="flex" justifyContent="center" m={1} p={2}>
                            <Box>
                                <Typography style={styles.header} variant="body1" component="h6">
                                    User Details
                                </Typography>
                                <Box display="flex" flexDirection="column">
                                    <TextField
                                        value={firstName}
                                        onChange={setFirstNameForValidation}
                                        label="First Name"
                                        error={!isNameValid && !isEmpty(firstName)}
                                        required={true}
                                        helperText={
                                            !isNameValid && !isEmpty(firstName)
                                                ? 'Please enter a valid first name.'
                                                : ''
                                        }
                                        style={styles.textField}
                                    />
                                    <TextField
                                        value={lastName}
                                        onChange={setLastNameForValidation}
                                        label="Last Name"
                                        error={!isLastNameValid && !isEmpty(lastName)}
                                        required={true}
                                        helperText={
                                            !isLastNameValid && !isEmpty(lastName)
                                                ? 'Please enter a valid last name.'
                                                : ''
                                        }
                                        style={styles.textField}
                                    />
                                    <TextField
                                        value={email}
                                        onChange={setEmailValidation}
                                        label="Email"
                                        error={!isEmailValid && !isEmpty(email)}
                                        required={true}
                                        helperText={
                                            !isEmailValid && !isEmpty(email)
                                                ? 'Please enter a valid email.'
                                                : ''
                                        }
                                        style={styles.textField}
                                    />
                                </Box>

                                <Typography
                                    style={{ marginTop: 20 }}
                                    variant="body1"
                                    component="h6"
                                >
                                    User Roles
                                </Typography>
                                <UserRoles
                                    isRolesValid={isRolesValid}
                                    setIsRolesValid={setIsRolesValid}
                                    onFormUpdate={onFormUpdate}
                                    setUserRoles={setUserRoles}
                                    userRoles={userRoles}
                                />
                                <Box>
                                    <FormControlLabel
                                        style={styles.noMargin}
                                        control={
                                            <Switch
                                                checked={active}
                                                name="active-user"
                                                color="primary"
                                                disabled={!isEdit}
                                                onChange={(event) => {
                                                    setActive(event.target.checked);
                                                    onFormUpdate();
                                                }}
                                            />
                                        }
                                        label="Active user"
                                        labelPlacement="start"
                                    />
                                </Box>

                                <Box display="flex" justifyContent="center" p={1}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        style={styles.btnCancel}
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!isValidForm}
                                        onClick={submitUser}
                                        styles={styles.btn}
                                    >
                                        {isEdit ? 'Update' : 'Create'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Grid>
                <Grid item xl={4} lg={4} md={3} sm={2} xs={12}></Grid>
            </Grid>
        </div>
    );
}

UserFormElement.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    active: PropTypes.bool,
    userRoles: PropTypes.array,
    setActive: PropTypes.func,
    setUserRoles: PropTypes.func,
    isRolesValid: PropTypes.bool,
    setIsRolesValid: PropTypes.func,
    isNameValid: PropTypes.bool,
    isLastNameValid: PropTypes.bool,
    isEmailValid: PropTypes.bool,
    setFirstNameForValidation: PropTypes.func,
    setLastNameForValidation: PropTypes.func,
    setEmailValidation: PropTypes.func,
    isValidForm: PropTypes.bool,
    submissionError: PropTypes.bool,
    loading: PropTypes.bool,
    isEdit: PropTypes.bool,
    submitUser: PropTypes.func,
    onFormUpdate: PropTypes.func,
    closeErrorModal: PropTypes.func,
    onCancel: PropTypes.func
};
