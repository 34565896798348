import { colors } from '../../assets/styles/colors';

export const styles = {
    container: {
        border: `dashed ${colors.navyOne} 1.5px`,
        borderRadius: '5px',
        height: 200,
        width: '100%',
        cursor: 'pointer',
        display: 'flex-box',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10
    },
    uploadArea: {
        marginTop: 32,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'grey',
        fontSize: 36
    },
    btnContainer: { width: '100%', textAlign: 'center', marginTop: 25 },
    buttonText: {
        padding: 10
    },
    center: {
        textAlign: 'center',
        width: '100%'
    },
    text: { wordWrap: 'break-word', fontSize: 15 }
};
