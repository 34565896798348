import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../../assets/styles/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { successModal as styles } from '../preview-document.styles';
import { useLocation } from 'react-router-dom';
import { documentService } from '../../../../services';
import { notifyError, notifyInfo } from '../../../../services/toast.service';
import moment from 'moment';
import { DATE_FORMATS } from '../../../../utils/date-format.enum';

export function MetadataDeleteModal(props) {
    const { handleClose, open, ocrDataToDelete } = props;
    const [metadata, setMetadata] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location && location.state) {
            const { metadata } = location.state;
            if (metadata != null) {
                setMetadata(metadata);
            }
        }
    }, [location]);

    const handleDelete = () => {
        const { data, ocrEntity } = ocrDataToDelete;
        if (metadata !== null) {
            const { internalOcr } = metadata;
            switch (ocrEntity) {
                case 'Emails':
                    internalOcr.emails = arrayRemove(internalOcr.emails, data);
                    break;
                case 'Dates':
                    internalOcr.dates = arrayRemove(internalOcr.dates, data);
                    break;
                case 'Phone Numbers':
                    internalOcr.phoneNumbers = arrayRemove(internalOcr.phoneNumbers, data);
                    break;
                case 'Websites':
                    internalOcr.urls = arrayRemove(internalOcr.urls, data);
                    break;
                default:
                    break;
            }

            documentService
                .modifyDocument(metadata.id, { internalOcr })
                .then(() => {
                    notifyInfo(`${ocrDataToDelete.ocrEntity.slice(0, -1)} successfully deleted.`);
                    handleClose();
                })
                .catch(() => {
                    notifyError(
                        `Failed to delete ${ocrDataToDelete.ocrEntity.slice(0, -1).toLowerCase()}`
                    );
                });
        }
    };

    const arrayRemove = (arr, value) => {
        return arr.filter(function (ele) {
            return ele.name !== value;
        });
    };

    return (
        <Dialog
            container={() => document.getElementById('app-layout')}
            aria-labelledby="negative-action"
            open={open}
            style={{ padding: 20 }}
        >
            <IconButton onClick={handleClose} style={styles.cancelIcon}>
                <CloseIcon fontSize="small" />
            </IconButton>
            <MuiDialogContent style={styles.dialog}>
                <Grid spacing={3} direction="column" container>
                    <Typography style={styles.centerSelf} variant="h6">
                        {`You are about to delete a ${ocrDataToDelete.ocrEntity
                            .slice(0, -1)
                            .toLowerCase()}`}
                    </Typography>
                    <Typography
                        style={{ ...styles.textMessage, ...styles.dialog }}
                        variant="subtitle1"
                        component="p"
                    >
                        {ocrDataToDelete.ocrEntity === 'Dates'
                            ? moment(ocrDataToDelete.data, DATE_FORMATS.StoredDate).format(
                                  DATE_FORMATS.FriendlyDate
                              )
                            : ocrDataToDelete.data}
                    </Typography>
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions style={styles.btnContainer}>
                <Button
                    style={{ minWidth: 120 }}
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                >
                    {'Cancel'}
                </Button>
                <Button
                    style={{
                        color: colors.whiteOne,
                        backgroundColor: colors.redOne,
                        minWidth: 120
                    }}
                    size="large"
                    variant="contained"
                    onClick={handleDelete}
                >
                    {`Delete ${ocrDataToDelete.ocrEntity.slice(0, -1).toLowerCase()}`}
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}

MetadataDeleteModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    ocrDataToDelete: PropTypes.object
};
