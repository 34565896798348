import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { SelectCustomerColumns } from './select-customer-grid-col-def';
import { SelectCustomerGridElement } from './select-customer-grid.element';
import { customerService } from '../../services';
import { isEmpty, isNil, debounce } from 'lodash';
import PropTypes from 'prop-types';
import { UserProfileContext } from '../../store/user-profile.context';
import { notifyError } from '../../services/toast.service';

export function SelectCustomerGrid(props) {
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(100);
    const [page, setPage] = useState(0);
    const [sortModel, setSortModel] = useState([
        {
            field: 'firstName',
            sort: 'asc'
        }
    ]);
    const [filter, setFilter] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(true);
    const userProfileContext = useContext(UserProfileContext);
    const partnerId = userProfileContext.userProfile.partnerId;
    const { onSelectedChanged } = props;
    const [selectionModel, setSelectionModel] = useState([]);
    const prevSelectionModel = useRef(selectionModel);

    useEffect(async () => {
        await getCustomers();
        setTimeout(() => {
            setSelectionModel(prevSelectionModel.current);
        }, 500);
    }, [page, pageSize, sortModel]);

    const getCustomers = () => {
        const fetchOptions = {
            pageNumber: page,
            pageSize,
            sortBy: sortModel[0],
            partnerId
        };

        setLoading(true);
        if (!isEmpty(searchText)) {
            customerService
                .getCustomersBySearch({ ...fetchOptions, searchText })
                .then(({ rows, totalRows }) => {
                    setTotalRows(totalRows);
                    setData({ ...data, rows: rows });
                    setLoading(false);
                })
                .catch(handleError);
            return;
        }

        if (!isNil(filter.items)) {
            if (!isEmpty(filter?.items[0]?.value)) {
                customerService
                    .getCustomersByFilter({ ...fetchOptions, filter: filter.items[0] })
                    .then(({ rows, totalRows }) => {
                        setTotalRows(totalRows);
                        setData({ ...data, rows: rows });
                        setLoading(false);
                    })
                    .catch(handleError);
                return;
            }
        }

        customerService
            .getCustomers(fetchOptions)
            .then(({ rows, totalRows }) => {
                setTotalRows(totalRows);
                setData({ ...data, rows: rows });
                setLoading(false);
            })
            .catch(handleError);
    };

    const [data, setData] = useState({
        rows: [],
        columns: SelectCustomerColumns(getCustomers)
    });

    const requestSearch = (searchValue) => {
        setLoading(true);
        setSearchText(searchValue);

        const options = {
            pageNumber: 0,
            pageSize: 10,
            searchText: searchValue,
            partnerId
        };

        customerService
            .getCustomersBySearch(options)
            .then(({ rows, totalRows }) => {
                setLoading(false);
                setTotalRows(totalRows);
                setData({ ...data, rows });
            })
            .catch(handleError);
    };

    const onSearchTermChange = (term) => {
        setSearchText(term);
        debounceSearch(term);
    };

    const debounceSearch = useCallback(
        debounce((term) => requestSearch(term), 500),
        []
    );

    const onClearSearch = () => {
        requestSearch('');
    };

    const requestFilter = (filterModel) => {
        setLoading(true);

        const fetchOptions = {
            pageNumber: page,
            pageSize,
            filter: filterModel.items[0],
            partnerId
        };

        if (isNil(filterModel.items[0])) {
            return;
        }

        customerService
            .getCustomersByFilter(fetchOptions)
            .then(({ rows, totalRows }) => {
                setTotalRows(totalRows);
                setData({ ...data, rows: rows });
                setLoading(false);
            })
            .catch(handleError);
    };

    const onFilterModelChange = (filterModel) => {
        setFilter(filterModel);
        debounceFilter(filterModel);
    };

    const debounceFilter = useCallback(
        debounce((filterModel) => requestFilter(filterModel), 800),
        []
    );

    const handleError = () => {
        setLoading(false);
        notifyError('Something went wrong whilst fetching the customers');
    };

    const onPageChange = (newPage) => {
        prevSelectionModel.current = selectionModel;
        setPage(newPage);
    };

    const onSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
        onSelectedChanged(newSelectionModel);
    };

    return (
        <SelectCustomerGridElement
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
            data={data}
            pageSize={pageSize}
            searchText={searchText}
            totalRows={totalRows}
            loading={loading}
            sortModel={sortModel}
            setPageSize={setPageSize}
            onPageChange={onPageChange}
            setSortModel={setSortModel}
            onClearSearch={onClearSearch}
            onSearchTermChange={onSearchTermChange}
            onFilterModelChange={onFilterModelChange}
        />
    );
}

SelectCustomerGrid.propTypes = {
    onSelectedChanged: PropTypes.func
};
