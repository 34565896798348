import React from 'react';

export function ZAFlag() {
    return (
        // <svg
        //     className="MuiSvgIcon-root"
        //     focusable="true"
        //     aria-hidden="true"
        //     x="0px"
        //     y="0px"
        //     viewBox="0 0 100 125"
        //     xmlSpace="preserve"
        // >
        //     <path d="M93,22H62.2H49.1H16.9H7c-1.1,0-2,0.9-2,2v9.9v0c0,0,0,0,0,0v32.2c0,0,0,0,0,0v0V76c0,1.1,0.9,2,2,2h9.9h32.2h13.1H93  c1.1,0,2-0.9,2-2V57V43V24C95,22.9,94.1,22,93,22z M91,41H44.5L23.2,26h25.9h13.1H91V41z M9,37.6L27.5,50L9,62.4V37.6z M9,67.2  l23.2-15.5c0.6-0.4,0.9-1,0.9-1.7s-0.3-1.3-0.9-1.7L9,32.8V26h7.3l26.5,18.6c0.3,0.2,0.7,0.4,1.2,0.4H91v10H43.9  c-0.4,0-0.8,0.1-1.2,0.4L16.3,74H9V67.2z M62.2,74H49.1H23.2l21.3-15H91v15H62.2z" />
        // </svg>

        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            className="MuiSvgIcon-root"
            focusable="true"
            aria-hidden="true"
            viewBox="0 0 9 6"
        >
            <clipPath id="c">
                <path d="M0 0l4.5 3L0 6" id="b" />
            </clipPath>
            <clipPath id="a">
                <path d="M0 0h9v6H0z" />
            </clipPath>
            <g clipPath="url(#a)">
                <path d="M0 0v6h9V0z" fill="#002395" />
                <path d="M0 0v3h9V0z" fill="#de3831" />
                <g strokeWidth="2" stroke="#fff">
                    <path d="M0 0l4.5 3L0 6m4.5-3H9" id="d" />
                    <use xlinkHref="#b" stroke="#ffb612" clipPath="url(#c)" />
                </g>
                <use xlinkHref="#d" fill="none" stroke="#007a4d" strokeWidth="1.2" />
            </g>
        </svg>
    );
}
