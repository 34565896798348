import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/styles/colors';

export function NotFoundPage() {
    return (
        <>
            <Grid>
                <Grid item xs={12}>
                    <Typography align="center" variant="h5" component="div">
                        <FontAwesomeIcon icon="search" size="10x" color={colors.navyOne} />
                    </Typography>
                    <div style={{ height: '100px' }}></div>
                    <Typography align="center" variant="h5" component="h5">
                        error 404 page not found!
                    </Typography>
                    <Typography align="center" variant="h6" component="h6">
                        go back to the
                        <Link to="/home" replace>
                            {' home '}
                        </Link>
                        page
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
