import { colors } from '../../assets/styles/colors';

export const styles = {
    container: {
        position: 'relative'
    },
    content: {
        position: 'absolute',
        backgroundColor: '#fff',
        height: '23vw',
        width: '14vw',
        marginTop: '5vw',
        marginLeft: '0.5vw',
        padding: '0.3vw',
        overflow: 'auto',
        fontSize: '0.5vw',
        textAlign: 'center',
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px'
    },
    title: {
        fontSize: '1vw',
        fontWeight: 'bold'
    },
    body: {
        fontSize: '0.8vw'
    },
    url: {
        fontSize: '0.8vw'
    },
    phone: {
        position: 'relative',
        backgroundColor: '#000',
        borderRadius: '1.5vw',
        height: '30vw',
        width: '15vw',
        overflow: 'hidden'
    },
    phoneHeader: {
        position: 'absolute',
        height: '3vw',
        width: '14vw',
        top: '2vw',
        left: 0,
        backgroundColor: '#fff',
        marginLeft: '0.5vw',
        borderBottom: '1px solid #ddd',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px'
    },
    logo: {
        width: '3vw',
        margin: '1vw'
    },
    image: {
        width: '13vw'
    },
    btnContinue: {
        fontSize: '0.7vw',
        backgroundColor: colors.turquoiseTwo,
        color: '#fff',
        borderRadius: '2vw',
        width: '11vw',
        height: '2vw',
        lineHeight: '2vw',
        marginTop: '0.5vw'
    },
    btnContinueHolder: {
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center'
    }
};
