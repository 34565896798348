import React, { useState, useEffect } from 'react';
import { Grid, Typography, Container, Button } from '@material-ui/core';
import { AddMarketingMessageForm } from '../../../components/marketing-messages/add-marketing-message-form.container';
import { MarketingMessagePhonePreview } from '../../../components/marketing-messages/marketing-message-phone-preview.element';
import { styles, useStyles } from '../create-marketing-message/create-marketing-message.styles';
import { marketingMessageService } from '../../../services';
import { Spinner } from '../../../components/spinner/spinner';
import { useHistory, useParams } from 'react-router-dom';

export function PreviewMarketingMessagePage() {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [url, setURL] = useState('');
    const [urlText, setURLTextLabel] = useState('');
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        if (id != null) {
            setLoading(true);
            marketingMessageService.getMarketingMessage(id).then((result) => {
                setTitle(result.title);
                setBody(result.body);
                setURL(result.linkUrl);
                setURLTextLabel(result.linkText);
                if (result.blobId) {
                    marketingMessageService.getUnencryptedBlob(result.blobId).then((image) => {
                        setLoading(false);
                        setImage(image);
                    });
                } else {
                    setLoading(false);
                }
            });
        }
    }, [id]);

    const goBack = () => {
        history.goBack();
    };

    return (
        <Container>
            <Spinner loading={loading} />
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <div className={classes.root}>
                    <div style={styles.tittleBox}>
                        <Typography variant="h4" component="span">
                            Marketing Message Preview
                        </Typography>
                    </div>
                    <div style={styles.buttonBox}>
                        <Button
                            className={classes.mr}
                            size="large"
                            variant="outlined"
                            color="secondary"
                            onClick={goBack}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '30vw' }}>
                        <AddMarketingMessageForm
                            editable={false}
                            title={title}
                            body={body}
                            url={url}
                            urlText={urlText}
                            setTitle={() => {}}
                            setBody={() => {}}
                            setURL={() => {}}
                            setURLTextLabel={() => {}}
                            processFile={() => {}}
                            setIsValidForm={() => {}}
                            setSubmissionError={() => {}}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <MarketingMessagePhonePreview
                        title={title}
                        body={body}
                        url={url}
                        image={image}
                        urlText={urlText === '' ? url : urlText}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
