import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DocumentMetadataPreviewElement } from './document-metadata-preview/document-metadata-preview.element';
import { styles, useStyles } from './preview-document.styles';
import { fileService } from '../../../services/file.service';
import { documentService } from '../../../services';
import { notifyError } from '../../../services/toast.service';

export function PreviewDocumentPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();
    const [metadata, setMetadata] = useState({});
    const [dataURI, setDataURI] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (location && location.state) {
            const { metadata } = location.state;
            if (metadata != null) {
                setMetadata(metadata);
                getFile(metadata);
            } else {
                documentService.getDocument(id).then((data) => {
                    setMetadata(data).catch(() => {
                        notifyError(`Something went wrong while loading up document metadata.`);
                    });
                });
            }
        }
    }, [location, id]);

    const goBack = () => {
        history.goBack();
    };

    const getFile = (metadata) => {
        const { blobFileId, privateMetadataId } = metadata;
        setIsLoading(true);
        fileService
            .getFile(blobFileId, privateMetadataId)
            .then((uri) => {
                setIsLoading(false);
                setDataURI(uri);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    return (
        <Grid container spacing={1} direction="row">
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <div className={classes.root}>
                    <div style={styles.tittleBox}>
                        <Typography variant="h4" component="span">
                            Document Preview
                        </Typography>
                    </div>
                    <div style={styles.buttonBox}>
                        <Button
                            className={classes.mr}
                            size="large"
                            variant="outlined"
                            color="secondary"
                            onClick={goBack}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={8} justifyContent="center">
                        {isLoading ? (
                            <div
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}
                            >
                                <CircularProgress color="primary" />
                            </div>
                        ) : (
                            <object width="100%" height="680"  data={dataURI} type={metadata.type}>
                                <p>{metadata.name}</p>
                            </object>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <DocumentMetadataPreviewElement data={metadata} dataURI={dataURI} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
