import { authenticationService } from './authentication.service';
import { GATEWAY_API_BASE_URL } from '../config';
import axios from 'axios';
import { PROXY_FUNCTIONS } from '@entelectsoftwaresa/rgaxuk-common/src';

const baseURL = `${GATEWAY_API_BASE_URL}/`
function createRequestHeader() {
    const bearerToken = authenticationService.getToken();
    return {
        Authorization: `Bearer ${bearerToken}`
    };
}

const axiosInstance = axios.create({
    baseURL,
    timeout: 3000,
    headers: createRequestHeader(),
    responseType: 'application/json'
})

async function get(path) {
    const response = await axiosInstance.get(path, {
        responseType: "json"
    });
    return response.data;
}

async function post(path, data) {
    return await axiosInstance.post(path, data);
}

async function patch(path, data) {
    return await axiosInstance.patch(path, data);
}

async function postFile(path, data) {
    const url = `${GATEWAY_API_BASE_URL}/${path}`;
    const headers = createRequestHeader();
    const request = {
        url,
        headers,
        data
    };
    return await PROXY_FUNCTIONS.postMultiFormData(request);
}

export const httpService = {
    post,
    postFile,
    patch,
    get
};
