import React, { useState, useEffect } from 'react';
import { EditUserForm } from '../add-user/add-user-form/edit-user-form.container';
import { userProfileService } from '../../../services';
import { useParams } from 'react-router-dom';

export function EditUserPage() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setLoading(true);
            userProfileService.getUserById(id).then((user) => {
                setFirstName(user.firstName);
                setLastName(user.lastName);
                setEmail(user.emailAddress);
                setUserRoles(user.roles);
                setActive(user.isActive);
                setLoading(false);
            });
        }
    }, [id]);

    return (
        <>
            <EditUserForm
                userId={id}
                firstName={firstName}
                lastName={lastName}
                email={email}
                active={active}
                userRoles={userRoles}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setActive={setActive}
                setUserRoles={setUserRoles}
                loading={loading}
                setLoading={setLoading}
            />
        </>
    );
}
