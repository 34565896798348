import { authenticationService, partnerProfileService, rolesService, userProfileService } from "../services";
import { LOCAL_STORAGE_KEYS, saveTokenToLocalStorage } from '../utils/localStorage';
import { isEqual } from 'lodash';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import GLOBAL from '../app/global';

export const fetchUserProfile = async ({ auth0SubProfile, logout, setUserProfile, setUserPermissions, setPartnerProfile, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, userProfile, userPermissions }) => {
    try {
        if (isAuthenticated && authenticationService.isAuthTokenExpired()) {
            const token = await getAccessTokenSilently();
            saveTokenToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, token, 36000);
            const idToken = getIdTokenClaims();
            saveTokenToLocalStorage(LOCAL_STORAGE_KEYS.ID_TOKEN, idToken, 36000);
        }

        const profile = await userProfileService.getUser(auth0SubProfile.sub.replace('auth0|', ''));
        if (!profile.isActive) {
            logout();
        }

        if (!isEqual(userProfile, profile)) {
            setUserProfile(profile);
        }

        await fetchPartnerProfile(profile.partnerId, setPartnerProfile);

        const partnerRoles = await rolesService.getPartnerRoles(profile.partnerId);
        const permissionsArr = [];

        profile.roles.forEach(userRole => {
            const partnerRole = partnerRoles.find(({ roleId }) => roleId === userRole);
            if (partnerRole) {
                partnerRole.permissions.forEach(permission => {
                    permissionsArr.push(PERMISSIONS.permissions[permission]);
                });
            }
        });

        if (userPermissions.length === 0 || !isEqual(userPermissions, permissionsArr)) {
            GLOBAL.userPermissions = permissionsArr;
            setUserPermissions(permissionsArr);
        }
    }
    catch(_) {
        logout();
    }
};

export const fetchPartnerProfile = (partnerId, setPartnerProfile) => {
    if (partnerId) {
        partnerProfileService.getPartnerProfile(partnerId).then(profile => {
            setPartnerProfile(profile);
        });
    }
};
