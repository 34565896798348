import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './user-profile-grid.styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { colors } from '../../../../assets/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NegativeActionModal } from '../../../../components/negative-action-modal/negative-action-modal.element';
import { Spinner } from '../../../../components/spinner/spinner';
import { userProfileService } from '../../../../services';
import { SuccessModal } from '../../../../components/positive-action-modal/success-modal.element';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import { hasPermission } from '../../../../utils/permission-checker';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../services/toast.service';

export function UserProfileGridActions(props) {
    const { params, reloadGrid } = props;
    const [isUnlinking, setIsUnlinking] = useState(false);
    const [isLinking, setIslinking] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    const editAction = (id) => {
        history.push('edit-user/' + id);
    };

    const deactivateUserAction = (event) => {
        event.stopPropagation();
        setIsUnlinking(true);
    };

    const reactivateUserAction = (event) => {
        event.stopPropagation();
        setIslinking(true);
    };

    const closeUnlinkingModal = () => {
        setIsUnlinking(false);
    };

    const closeLinkingModal = () => {
        setIslinking(false);
    };

    const deactivateUser = () => {
        setIsLoading(true);
        toggleUserIsActiveFlag(
            params.row,
            `${params.row.firstName} ${params.row.lastName} successfully de-activated`,
            `deactivating ${params.row.firstName} ${params.row.lastName}`
        );
        closeUnlinkingModal();
    };

    const reactivateUser = () => {
        setIsLoading(true);
        toggleUserIsActiveFlag(
            params.row,
            `${params.row.firstName} ${params.row.lastName} successfully activated`,
            `reactivating ${params.row.firstName} ${params.row.lastName}`
        );
        closeLinkingModal();
    };

    const toggleUserIsActiveFlag = (row, successMessage, errorMessage) => {
        userProfileService
            .setUserIsActive(row.id, !row.isActive)
            .then(() => {
                setIsLoading(false);
                notifySuccess(successMessage);
                reloadGrid();
            })
            .catch(() => {
                setIsLoading(false);
                notifyError(`Something went wrong while ${errorMessage}`);
            });
    };

    const disabled = !hasPermission(PERMISSIONS.permissions.EditUsers);

    return (
        <div className={classes.iconRoot}>
            <Spinner loading={isLoading} />
            <NegativeActionModal
                open={isUnlinking}
                handleClose={closeUnlinkingModal}
                handleAction={deactivateUser}
                mainText={[
                    'You are about to de-activate',
                    `${params.row.firstName} ${params.row.lastName}`
                ]}
                subText={['This user will no longer be able', 'to log in to the system']}
                buttonText1="Cancel"
                buttonText2="De-activate"
            />
            <SuccessModal
                open={isLinking}
                handleClose={closeLinkingModal}
                primaryAction={reactivateUser}
                secondaryAction={closeLinkingModal}
                mainText={[
                    'You are about to re-activate',
                    `${params.row.firstName} ${params.row.lastName}`
                ]}
                subText={['This user will will be able', 'to log in to the system again']}
                buttonText1="Cancel"
                buttonText2="Re-activate"
            />
            <Tooltip title="Edit user" placement="top">
                <span>
                    <IconButton
                        disabled={disabled}
                        size="small"
                        aria-label="edit"
                        onClick={() => editAction(params.row.id)}
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'pencil-alt']}
                            color={disabled ? colors.greyOne : colors.turquoiseOne}
                        />
                    </IconButton>
                </span>
            </Tooltip>
            {params.row.isActive && (
                <Tooltip title="De-activate user">
                    <span>
                        <IconButton
                            disabled={disabled}
                            size="small"
                            aria-label="delete"
                            onClick={deactivateUserAction}
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'ban']}
                                color={disabled ? colors.greyOne : colors.hotPinkOne}
                            />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
            {!params.row.isActive && (
                <Tooltip title="Re-activate user">
                    <span>
                        <IconButton
                            disabled={disabled}
                            size="small"
                            aria-label="delete"
                            onClick={reactivateUserAction}
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'sync']}
                                color={disabled ? colors.greyOne : colors.greenOne}
                            />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
        </div>
    );
}

UserProfileGridActions.propTypes = {
    id: PropTypes.string,
    params: PropTypes.object,
    handleDeleteClick: PropTypes.func,
    handleEditClick: PropTypes.func,
    reloadGrid: PropTypes.func
};
