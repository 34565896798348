import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './document-grid.styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { colors } from '../../../../assets/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { NegativeActionModal } from '../../../../components/negative-action-modal/negative-action-modal.element';
import { documentService, eventService } from '../../../../services';
import {
    FILE_STATUSES,
    EVENT_TYPES,
    SOURCE_OF_INVITES
} from '@entelectsoftwaresa/rgaxuk-common-enums';
import { PartnerProfileContext } from '../../../../store/partner-profile.context';
import { notifyError, notifySuccess } from '../../../../services/toast.service';

export function DocumentGridActions(props) {
    const [isDeleting, setIsDeleting] = useState(false);

    const partnerProfileContext = useContext(PartnerProfileContext);

    const { params, reloadGrid } = props;
    const classes = useStyles();

    const shareAction = (event) => {
        event.stopPropagation();
        const { row: documentMetadata } = params;

        if (documentMetadata.status === FILE_STATUSES.allProcessingComplete) {
            const eventConfig = {
                type:
                    EVENT_TYPES.inviteToPartnerDocumentSpace || 'INVITE_TO_PARTNER_DOCUMENT_SPACE',
                payload: {
                    ownerUserId: documentMetadata.partnerId,
                    affiliateId: documentMetadata.partnerId,
                    documentSpaceId: documentMetadata.documentSpaceIds[0],
                    sourceOfInvite: SOURCE_OF_INVITES.affiliate,
                    documentName: documentMetadata.name,
                    documentType: documentMetadata.type,
                    blobFileId: documentMetadata.blobFileId,
                    privateMetadataId: documentMetadata.privateMetadataId,
                    nameOfSender: partnerProfileContext.affiliateName,
                    affiliateLogo: partnerProfileContext.logoImage
                }
            };

            eventService
                .createEvent(eventConfig)
                .then(() => {
                    notifySuccess(
                        `Shared ${params.row.name} has been successfully shared with ${params.row.customerCount} customers`
                    );
                })
                .catch(() => {
                    notifyError(
                        'Something went wrong while sharing the document, please try again.'
                    );
                });
        }
    };

    const openNegativeAction = () => {
        setIsDeleting(true);
    };

    const closeNegativeAction = () => {
        setIsDeleting(false);
    };
    const deleteDocument = async () => {
        try {
            await documentService.deleteDocument(
                params.row.id,
                params.row.partnerId,
                params.row.blobFileId
            );
            notifySuccess('Document was deleted successfully.');
            reloadGrid(0);
            closeNegativeAction();
        } catch (error) {
            console.log(error);
            notifyError('Something went wrong while deleting the document, please try again.');
            closeNegativeAction();
        }
    };

    return (
        <div className={classes.iconRoot}>
            <NegativeActionModal
                open={isDeleting}
                handleClose={closeNegativeAction}
                handleAction={deleteDocument}
                mainText={['You are about to delete', `${params.row.name}`]}
                subText={['You will no longer have access', 'to this document.']}
                buttonText1="Cancel"
                buttonText2="Delete"
            />
            <Tooltip title="Review document share" placement="top">
                <Link
                    to={{
                        pathname: `/${params.row.blobFileId}/preview-document`,
                        state: { metadata: params.row }
                    }}
                    style={{ textDecoration: 'none' }}
                >
                    <IconButton size="small" aria-label="edit">
                        <FontAwesomeIcon icon={['fas', 'eye']} color={colors.turquoiseOne} />
                    </IconButton>
                </Link>
            </Tooltip>
            <Tooltip title="Share document">
                <IconButton
                    size="small"
                    aria-label="unlink"
                    onClick={shareAction}
                    disabled={params.row.status !== FILE_STATUSES.allProcessingComplete}
                >
                    <FontAwesomeIcon
                        icon={['fas', 'share-square']}
                        color={
                            params.row.status === FILE_STATUSES.allProcessingComplete
                                ? colors.navyOne
                                : colors.lightBlueGray100
                        }
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Remove document" placement="top">
                <IconButton size="small" aria-label="unlink" onClick={openNegativeAction}>
                    <FontAwesomeIcon icon={['fas', 'trash-alt']} color={colors.hotPinkOne} />
                </IconButton>
            </Tooltip>
        </div>
    );
}

DocumentGridActions.propTypes = {
    params: PropTypes.object,
    handleDeleteClick: PropTypes.func,
    handleEditClick: PropTypes.func,
    reloadGrid: PropTypes.func
};
