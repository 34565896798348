import React, { useContext } from 'react';
import { DashboardButton } from '../../components/dashboard-button/dashboard-button.element';
import { Banner } from '../../components/banner/banner.element';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router';
import { UserProfileContext } from '../../store/user-profile.context';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import { hasPermission } from '../../utils/permission-checker';
import { isNil } from 'lodash';
import GLOBAL from '../../app/global';

export function HomePage() {
    const history = useHistory();

    const goTo = (path) => {
        history.push(path);
    };

    const userProfileContext = useContext(UserProfileContext);
    const { userProfile } = userProfileContext;

    if (isNil(userProfile) || GLOBAL.userPermissions.length < 1) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <CircularProgress style={{ height: 100, width: 100 }} color="primary" />
            </div>
        );
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">
                        Welcome {userProfile?.firstName}
                    </Typography>
                    <Typography variant="h6" component="span">
                        What would you like to do today?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Banner />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {hasPermission(PERMISSIONS.permissions.CreateUsers) && (
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={11}>
                                <DashboardButton
                                    icon="user-plus"
                                    buttonText="Add new user"
                                    onClick={() => {
                                        goTo('add-user');
                                    }}
                                />
                            </Grid>
                        )}
                        {hasPermission(PERMISSIONS.permissions.CreateCustomers) && (
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={11}>
                                <DashboardButton
                                    icon="user-friends"
                                    buttonText="Add new customers"
                                    onClick={() => {
                                        goTo('/add-customer');
                                    }}
                                />
                            </Grid>
                        )}
                        {hasPermission(PERMISSIONS.permissions.ViewShares) && (
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={11}>
                                <DashboardButton
                                    icon="share-square"
                                    buttonText="Share a document"
                                    onClick={() => {
                                        goTo('documents');
                                    }}
                                />
                            </Grid>
                        )}
                        {hasPermission(PERMISSIONS.permissions.CreateMarketing) && (
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={11}>
                                <DashboardButton
                                    icon="bullhorn"
                                    buttonText="Create marketing message"
                                    onClick={() => {
                                        goTo('create-marketing-message');
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={11}>
                            <DashboardButton
                                icon="chart-pie"
                                buttonText="View analytics"
                                onClick={() => {}}
                            />
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={11}>
                            <DashboardButton
                                icon="id-badge"
                                buttonText="View your profile"
                                onClick={() => {}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ height: '100px' }}></div>
        </div>
    );
}
