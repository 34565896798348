import { FILE_STATUSES } from '@entelectsoftwaresa/rgaxuk-common-enums/src';

export function makeFileExtension(type) {
    let extension = '';
    switch (type) {
        case 'application/pdf':
            extension = 'pdf';
            break;
        case 'image/jpg':
        case 'image/jpeg':
            extension = 'jpg';
            break;
        case 'image/png':
            extension = 'png';
            break;
        default:
            break;
    }
    return extension;
}

export function makeFileStatusReadable(enumStatus) {
    let readableStatus = '';
    switch (enumStatus) {
        case FILE_STATUSES.new:
            readableStatus = 'New';
            break;
        case FILE_STATUSES.uploadStarted:
            readableStatus = 'Uploaded Started';
            break;
        case FILE_STATUSES.uploadCompleted:
            readableStatus = 'Uploaded';
            break;
        case FILE_STATUSES.documentIdentified:
            readableStatus = 'Document Identified';
            break;
        case FILE_STATUSES.uploadedForProcessing:
            readableStatus = 'Processing';
            break;
        case FILE_STATUSES.azureProcessingComplete:
            readableStatus = 'Processing Complete';
            break;
        case FILE_STATUSES.allProcessingComplete:
            readableStatus = 'Processing Complete';
            break;
        case FILE_STATUSES.uploadFailed:
            readableStatus = 'Upload Failed';
            break;
        case FILE_STATUSES.documentTooLarge:
            readableStatus = 'Document Too Large';
            break;
        case FILE_STATUSES.failedToProcess:
            readableStatus = 'Failed To Process';
            break;
        default:
            break;
    }
    return readableStatus;
}

export function makeFileStatusForSearch(status) {
    let backendStatus = '';
    switch (status) {
        case 'New':
            backendStatus = FILE_STATUSES.new;
            break;
        case 'Uploaded Started':
            backendStatus = FILE_STATUSES.uploadStarted;
            break;
        case 'Uploaded':
            backendStatus = FILE_STATUSES.uploadCompleted;
            break;
        case 'Document Identified':
            backendStatus = FILE_STATUSES.documentIdentified;
            break;
        case 'Processing':
            backendStatus = FILE_STATUSES.uploadedForProcessing;
            break;
        case 'Processing Complete':
            backendStatus = FILE_STATUSES.azureProcessingComplete;
            break;
        case 'Upload Failed':
            backendStatus = FILE_STATUSES.uploadFailed;
            break;
        case 'Document Too Large':
            backendStatus = FILE_STATUSES.documentTooLarge;
            break;
        case 'Failed To Process':
            backendStatus = FILE_STATUSES.failedToProcess;
            break;
        default:
            backendStatus = status;
    }
    return backendStatus;
}
