import React from 'react';
import { AddUserForm } from './add-user-form/add-user-form.container';

export function AddUserPage() {
    return (
        <>
            <AddUserForm />
        </>
    );
}
