import { colors } from '../../../../assets/styles/colors';

export const styles = {
    container: {
        border: 'solid grey',
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderRadius: '15px'
    },
    textField: {
        paddingBottom: 15
    },
    header: {
        marginBottom: 10
    },
    phoneInputStyles: {
        borderRadius: '25px',
        backgroundColor: '#fafafa'
    },
    phoneContainerStyles: {
        marginTop: 10
    },
    btnCancel: {
        borderColor: colors.redOne,
        color: colors.redOne,
        marginRight: 20
    },
    btn: {
        marginLeft: 10
    },
    phoneNumberOutline: {
        borderColor: 'red'
    },
    noMargin: {
        margin: 0,
        marginBottom: 10
    }
};
