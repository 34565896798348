import { colors } from '../../assets/styles/colors';

export const styles = {
    select: {
        display: 'flex',
        alignItems: 'center',
        borderWidth: 0
        // borderBottomWidth: 1,
        // borderStyle: 'solid',
        // borderColor: 'gray',
        // marginTop: 14.5,
    },
    activeSelect: {
        display: 'flex',
        alignItems: 'center',
        borderWidth: 0
        // borderBottomWidth: 2,
        // borderStyle: 'solid',
        // borderColor: colors.navyOne,
        // marginTop: 14.5,
    },
    onError: {
        display: 'flex',
        alignItems: 'center',
        borderWidth: 0,
        borderBottomWidth: 2,
        borderStyle: 'solid',
        borderColor: colors.redOne,
        marginBottom: 9.3
    },
    selectComponent: {
        margin: 0,
        padding: 0,
        backgroundColor: colors.transparent,
        borderBottomWidth: 0
    },
    root: {
        display: 'flex',
        alignItems: 'center'
    }
};
