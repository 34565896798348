import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    NotFoundPage,
    HomePage,
    ViewCustomersPage,
    AddCustomersPage,
    ViewDocumentsPage,
    AddDocumentPage,
    PreviewDocumentPage,
    AddUserPage,
    EditUserPage,
    ViewUsersPage,
    AddRolePage,
    EditRolePage,
    ViewRolesPage,
    ViewMarketingMessagesPage,
    CreateMarketingMessagePage,
    PreviewMarketingMessagePage
} from '../modules/';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import { hasPermission } from '../utils/permission-checker';

export function AppRouting() {
    return (
        <Switch>
            <Route
                exact
                path="/"
                render={() => {
                    return <Redirect to="home" />;
                }}
            />
            <Route path="/home">
                <HomePage />
            </Route>
            {hasPermission(PERMISSIONS.permissions.ViewUsers) && (
                <Route path="/users">
                    <ViewUsersPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.CreateUsers) && (
                <Route path="/add-user">
                    <AddUserPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.EditUsers) && (
                <Route path="/edit-user/:id">
                    <EditUserPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.ViewRoles) && (
                <Route path="/roles">
                    <ViewRolesPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.CreateRoles) && (
                <Route path="/add-role">
                    <AddRolePage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.EditRoles) && (
                <Route path="/edit-role/:id">
                    <EditRolePage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.ViewCustomers) && (
                <Route path="/customers">
                    <ViewCustomersPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.CreateCustomers) && (
                <Route path="/add-customer">
                    <AddCustomersPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.ViewShares) && (
                <Route path="/documents">
                    <ViewDocumentsPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.CreateShares) && (
                <Route path="/add-document">
                    <AddDocumentPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.ViewShares) && (
                <Route path="/:id/preview-document">
                    <PreviewDocumentPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.CreateShares) && (
                <Route path="/add-documents">
                    <AddDocumentPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.ViewMarketing) && (
                <Route path="/view-marketing-message">
                    <ViewMarketingMessagesPage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.CreateMarketing) && (
                <Route path="/create-marketing-message">
                    <CreateMarketingMessagePage />
                </Route>
            )}
            {hasPermission(PERMISSIONS.permissions.ViewMarketing) && (
                <Route path="/:id/preview-marketing-message">
                    <PreviewMarketingMessagePage />
                </Route>
            )}
            <Route path="*">
                <NotFoundPage />
            </Route>
        </Switch>
    );
}
