import React, { useState, useEffect } from 'react';
import { EditRoleForm } from '../add-role/add-role-form/edit-role-form.container';
import { rolesService } from '../../../services';
import { useParams } from 'react-router-dom';

export function EditRolePage() {
    const [roleName, setRoleName] = useState('');
    const [roleDescription, setRoleDescription] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setLoading(true);
            rolesService.getRoleById(id).then((role) => {
                setRoleName(role.roleName);
                setRoleDescription(role.roleDescription);
                setPermissions(role.permissions);
                setActive(role.isActive);
                setLoading(false);
            });
        }
    }, [id]);

    return (
        <>
            <EditRoleForm
                roleId={id}
                roleName={roleName}
                setRoleName={setRoleName}
                roleDescription={roleDescription}
                setRoleDescription={setRoleDescription}
                active={active}
                setActive={setActive}
                permissions={permissions}
                setPermissions={setPermissions}
                loading={loading}
                setLoading={setLoading}
            />
        </>
    );
}
