import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@material-ui/core/Divider';
import { colors } from '../../assets/styles/colors';
import { Link, useRouteMatch } from 'react-router-dom';
import { listStyles as styles } from './app-layout.styles';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import { hasPermission } from '../../utils/permission-checker';

const Icon = (name, match) => {
    return (
        <FontAwesomeIcon
            size="2x"
            icon={name}
            color={match ? colors.turquoiseOne : colors.whiteOne}
        />
    );
};

const CustomListItem = ({ to, icon, text }) => {
    const match = useRouteMatch({ path: to, exact: true });

    return (
        <>
            <Link to={to} style={styles.link}>
                <ListItem button>
                    <ListItemIcon>{Icon(icon, match)}</ListItemIcon>
                    <ListItemText primary={text} style={match ? styles.textActive : styles.text} />
                </ListItem>
            </Link>
            <Divider style={match ? styles.dividerActive : styles.divider} />
        </>
    );
};

CustomListItem.propTypes = {
    to: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string
};

export const mainListItems = () => (
    <div>
        <CustomListItem to="/home" icon="home" text="Home" />
        {hasPermission(PERMISSIONS.permissions.ViewUsers) && (
            <CustomListItem to="/users" icon="users-cog" text="System Users" />
        )}
        {hasPermission(PERMISSIONS.permissions.ViewRoles) && (
            <CustomListItem to="/roles" icon="tasks" text="System Roles" />
        )}
        {hasPermission(PERMISSIONS.permissions.ViewCustomers) && (
            <CustomListItem to="/customers" icon="users" text="Customers" />
        )}
        {hasPermission(PERMISSIONS.permissions.ViewShares) && (
            <CustomListItem to="/documents" icon="share-square" text="Documents" />
        )}
        {hasPermission(PERMISSIONS.permissions.ViewMarketing) && (
            <CustomListItem
                to="/view-marketing-message"
                icon="bullhorn"
                text="Marketing Messages"
            />
        )}
        <CustomListItem to="/" icon="chart-pie" text="Analytics" />
        {/* <CustomListItem to="/example" icon="question-circle" text="Examples" /> */}
    </div>
);
