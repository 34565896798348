import React from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { DocumentGrid } from './document-grid/document-grid.container';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    }
}));

export function ViewDocumentsPage(props) {
    const classes = useStyles();
    const history = useHistory();

    const goToAddDocumentsPage = () => {
        history.push('add-document');
    };

    return (
        <Grid container spacing={1} direction="row" styles={{ marginBottom: 100 }}>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <div className={classes.root}>
                    <div style={{ padding: 20, paddingLeft: 0 }}>
                        <Typography variant="h4" component="span">
                            Manage Documents
                        </Typography>
                    </div>
                    <div style={{ padding: 20, paddingRight: 0, paddingLeft: 0 }}>
                        <Button
                            size="large"
                            variant="contained"
                            color="secondary"
                            onClick={goToAddDocumentsPage}
                        >
                            Add a new Document
                        </Button>
                    </div>
                </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <DocumentGrid />
            </Grid>
        </Grid>
    );
}
