import { formatData, formatFilterForRequest } from '../utils/data-table-helpers';
import { makeFileStatusForSearch } from '../utils/file-helper';
import { httpService } from './http.service';

async function getDocument(blobFileId) {
    const url = `partner-documents/?blobFileId=${blobFileId}`;
    const response = await httpService.get(url);
    return await response.data[0];
}

async function getDocuments(options) {
    const { pageSize, pageNumber, sortBy, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `partner-documents-metadata?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&isActive=true&$sort[${
        sortBy.field
    }]=${sortBy.sort === 'asc' ? 1 : -1}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getDocumentsBySearch(options) {
    const { pageSize, pageNumber, searchText, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `partner-documents-metadata?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&isActive=true&$or[0][name][$search]=${searchText}&$or[1][status][$search]=${makeFileStatusForSearch(
        searchText
    )}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getDocumentsByFilter(options) {
    const { pageSize, pageNumber, filter, partnerId } = options;
    const skip = pageSize * pageNumber;
    const filterQuery = formatFilterForRequest(filter);

    const url = `partner-documents-metadata?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[name]=1&isActive=true&${filterQuery}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function deleteDocument(documentId) {
    const url = `partner-documents-metadata/${documentId}`;
    const response = await httpService.patch(url, { isActive: false });
    return response.data;
}

async function modifyDocument(documentId, data) {
    const url = `partner-documents-metadata/${documentId}`;
    const response = await httpService.patch(url, data);
    return response.data;
}

export const documentService = {
    getDocument,
    getDocuments,
    getDocumentsBySearch,
    getDocumentsByFilter,
    deleteDocument,
    modifyDocument
};
