/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { MarketingMessagesColumns } from './marketing-messages-grid-col-def';
import { MarketingMessagesGridElement } from './marketing-messages-grid.element';
import { marketingMessageService } from '../../../../services';
import { isEmpty, isNil, debounce } from 'lodash';
import { UserProfileContext } from '../../../../store/user-profile.context';
import { notifyError } from '../../../../services/toast.service';

export function MarketingMessagesGrid(props) {
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [sortModel, setSortModel] = useState([
        {
            field: 'createdAt',
            sort: 'desc'
        }
    ]);
    const [filter, setFilter] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [isFetching, setIsFetching] = useState(true);
    const userProfileContext = useContext(UserProfileContext);
    const partnerId = userProfileContext.userProfile.partnerId;

    useEffect(() => {
        getMarketingMessages();
    }, [page, pageSize, sortModel, userProfileContext.userProfile.partnerId]);

    const getMarketingMessages = () => {
        const fetchOptions = {
            pageNumber: page,
            pageSize,
            sortBy: sortModel[0],
            partnerId
        };

        setIsFetching(true);

        if (!isEmpty(searchText)) {
            marketingMessageService
                .getMarketingMessagesBySearch({ ...fetchOptions, searchText })
                .then(({ rows, totalRows }) => {
                    setIsFetching(false);
                    setTotalRows(totalRows);
                    setData({ ...data, rows: rows });
                })
                .catch(handleError);
            return;
        }

        if (!isNil(filter.items)) {
            if (!isEmpty(filter?.items[0]?.value)) {
                marketingMessageService
                    .getMarketingMessagesByFilter({ ...fetchOptions, filter: filter.items[0] })
                    .then(({ rows, totalRows }) => {
                        setIsFetching(false);
                        setTotalRows(totalRows);
                        setData({ ...data, rows: rows });
                    })
                    .catch(handleError);
                return;
            }
        }

        marketingMessageService
            .getMarketingMessages(fetchOptions)
            .then(({ rows, totalRows }) => {
                setIsFetching(false);
                setTotalRows(totalRows);
                setData({ ...data, rows: rows });
            })
            .catch(handleError);
    };

    const [data, setData] = useState({
        rows: [],
        columns: MarketingMessagesColumns(getMarketingMessages)
    });

    const requestSearch = (searchValue) => {
        setIsFetching(true);
        setSearchText(searchValue);

        const options = {
            pageNumber: 0,
            pageSize: 10,
            searchText: searchValue,
            partnerId
        };

        marketingMessageService
            .getMarketingMessagesBySearch(options)
            .then(({ rows, totalRows }) => {
                setIsFetching(false);
                setTotalRows(totalRows);
                setData({ ...data, rows });
            })
            .catch(handleError);
    };

    const onSearchTermChange = (term) => {
        setSearchText(term);
        debounceSearch(term);
    };

    const debounceSearch = useCallback(
        debounce((term) => requestSearch(term), 500),
        []
    );

    const onClearSearch = () => {
        requestSearch('');
    };

    const requestFilter = (filterModel) => {
        setIsFetching(true);

        const fetchOptions = {
            pageNumber: page,
            pageSize,
            filter: filterModel.items[0],
            partnerId
        };

        if (isNil(filterModel.items[0])) {
            return;
        }

        marketingMessageService
            .getMarketingMessagesByFilter(fetchOptions)
            .then(({ rows, totalRows }) => {
                setIsFetching(false);
                setTotalRows(totalRows);
                setData({ ...data, rows: rows });
            })
            .catch(handleError);
    };

    const onFilterModelChange = (filterModel) => {
        setFilter(filterModel);
        debounceFilter(filterModel);
    };

    const debounceFilter = useCallback(
        debounce((filterModel) => requestFilter(filterModel), 1000),
        []
    );

    const handleError = () => {
        setIsFetching(false);
        notifyError('Something went wrong while fetching the marketing messages.');
    };

    return (
        <MarketingMessagesGridElement
            data={data}
            pageSize={pageSize}
            searchText={searchText}
            totalRows={totalRows}
            isFetching={isFetching}
            sortModel={sortModel}
            setPageSize={setPageSize}
            setPage={setPage}
            setSortModel={setSortModel}
            onClearSearch={onClearSearch}
            onSearchTermChange={onSearchTermChange}
            onFilterModelChange={onFilterModelChange}
        />
    );
}

MarketingMessagesGrid.propTypes = {};
