import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    TextField,
    Select,
    MenuItem,
    SvgIcon,
    InputAdornment,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { GBFlag } from '../icons/gb.flag';
import { ZAFlag } from '../icons/za.flag';
import { styles } from './mobile-number-input.styles';
import { isEmpty } from 'lodash';

export function MobileNumberInputElement({
    number,
    isValid,
    prefix,
    onCountryChange,
    onNumberChange,
    country
}) {
    const [onSelectHover, setOnSelectHover] = useState(false);

    const toggleOnSelectHover = (value) => {
        setOnSelectHover(value);
    };

    return (
        <div
            onMouseEnter={() => toggleOnSelectHover(true)}
            onMouseLeave={() => toggleOnSelectHover(false)}
            style={styles.root}
        >
            <TextField
                value={number}
                onChange={onNumberChange}
                label="Mobile Number"
                variant="outlined"
                required
                fullWidth
                inputProps={{
                    maxLength: 10,
                    type: 'tel'
                }}
                error={!isValid && !isEmpty(number)}
                helperText={
                    !isValid && !isEmpty(number) ? 'Please enter a valid mobile number.' : ''
                }
                InputProps={{
                    startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
                    endAdornment: (
                        <div
                            style={
                                !isValid && !isEmpty(number)
                                    ? styles.onError
                                    : onSelectHover
                                    ? styles.activeSelect
                                    : styles.select
                            }
                        >
                            <SvgIcon component={country === 'gb' ? GBFlag : ZAFlag} />
                            <Select
                                styles={styles.selectComponent}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right'
                                    },
                                    getContentAnchorEl: null
                                }}
                                inputProps={{ disableUnderline: true }}
                                variant="standard"
                                value={country}
                                onChange={onCountryChange}
                                defaultValue={country}
                                IconComponent={KeyboardArrowDown}
                                disableUnderline={true}
                                renderValue={() => {
                                    return '';
                                }}
                            >
                                <MenuItem value="gb">
                                    <ListItemIcon>
                                        <SvgIcon component={GBFlag} />
                                    </ListItemIcon>
                                    <ListItemText primary="United Kingdom" />
                                </MenuItem>
                                <MenuItem value="za">
                                    <ListItemIcon>
                                        <SvgIcon component={ZAFlag} />
                                    </ListItemIcon>
                                    <ListItemText primary="South Africa" />
                                </MenuItem>
                            </Select>
                        </div>
                    )
                }}
            />
        </div>
    );
}

MobileNumberInputElement.propTypes = {
    number: PropTypes.string,
    prefix: PropTypes.string,
    country: PropTypes.string,

    isValid: PropTypes.bool,

    onNumberChange: PropTypes.func,
    onCountryChange: PropTypes.func
};
