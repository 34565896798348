import { isNil } from 'lodash';

export const formatData = (data) => {
    // data grid requires id not _id
    const arr = [];
    data.forEach((user) => {
        const newUser = { ...user, id: user._id };
        delete newUser._id;
        arr.push({
            ...newUser
        });
    });
    return arr;
};

export function formatFilterForRequest(filterModel) {
    const { columnField, operatorValue, value } = filterModel;
    if (operatorValue === 'is' && value === '') {
        return `$or[0][${columnField}]=true&$or[1][${columnField}]=false`; // for a boolean col field when any is chosen
    }
    return `${columnField}${formatOperator(operatorValue, isNil(value) ? '' : value)}`;
}

function formatOperator(operatorValue, value) {
    let operator = '';
    switch (operatorValue) {
        case 'contains':
        case 'startsWith':
        case 'endsWith':
            operator = `[$search]=${value}`;
            break;
        case 'isNotEmpty':
            operator = '[$ne]=';
            break;
        case 'equals':
            operator = `=${value}`;
            break;
        case 'isEmpty':
            operator = `=`;
            break;
        case 'is':
            operator = `=${value}`;
            break;
        default:
            break;
    }
    return operator;
}
