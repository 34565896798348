import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, Fade, makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export function Spinner({ loading }) {
    const classes = useStyles();

    return (
        <Modal
            open={loading}
            className={classes.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            aria-labelledby="spinner-in-motion"
            aria-describedby="processing-in-progress"
        >
            <Fade in={loading}>
                <CircularProgress color="primary" />
            </Fade>
        </Modal>
    );
}

Spinner.propTypes = {
    loading: PropTypes.bool
};
