import React, { useEffect, useState } from 'react';
import './font-awesome-icon.lib';
import { Footer } from '../components/footer/footer';
import { Spinner } from '../components/spinner/spinner';
import { AppLayout } from '../components/app-layout/app-layout.element';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { fetchUserProfile } from './app.functions';
import { UserProfileContext } from '../store/user-profile.context';
import { PartnerProfileContext } from '../store/partner-profile.context';

function AppContainer() {
    const [userProfile, setUserProfile] = useState({});
    const [userPermissions, setUserPermissions] = useState([]);
    const [partnerProfile, setPartnerProfile] = useState({});

    const {
        isLoading,
        error,
        isAuthenticated,
        user,
        getAccessTokenSilently,
        getIdTokenClaims,
        logout
    } = useAuth0();

    useEffect(() => {
        fetchUserProfile({ auth0SubProfile: user, logout, setUserProfile, setUserPermissions, setPartnerProfile, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, userProfile, userPermissions });
    }, [
        isAuthenticated,
        user,
        getIdTokenClaims,
        getAccessTokenSilently,
        userPermissions,
        logout,
        userProfile
    ]);


    if (error) {
        return (
            <div>
                <p>Oops... {error.message}</p>
            </div>
        );
    }

    if (isLoading) {
        return <Spinner loading={true}></Spinner>;
    }

  return (
      <UserProfileContext.Provider value={{ userProfile, userPermissions }}>
          <PartnerProfileContext.Provider value={partnerProfile}>
              <Router>
                    <Route path="/">
                        <AppLayout />
                    </Route>
                    <Footer />
              </Router>
          </PartnerProfileContext.Provider>
      </UserProfileContext.Provider>
  );
}

export default withAuthenticationRequired(AppContainer, {
    onRedirecting: () => <></>
});
