import React, { useState, useEffect, useContext } from 'react';
import { UpdatePasswordModal } from './update-password-modal/update-password-modal.element';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { UserProfileContext } from '../../store/user-profile.context';

const passwordUpdateCheck = (userProfileContext, setIsModalOpen) => {
    const lastUpdated = moment(userProfileContext.userProfile?.passwordUpdateDate);
    const now = moment(Date.now());
    const difference = now.diff(lastUpdated, 'days');
    const days = parseInt(process.env.REACT_APP_PASSWORD_UPDATE_DAYS ?? 30);
    setIsModalOpen(difference >= days);
};

export function AppAlerts() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { logout } = useAuth0();

    const userProfileContext = useContext(UserProfileContext);

    useEffect(() => {
        passwordUpdateCheck(userProfileContext, setIsModalOpen);
    }, [userProfileContext]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const cancelUpdate = () => {
        setIsModalOpen(false);
        logout(); // kick out because they don't want to update password
    };

    return (
        <div>
            <UpdatePasswordModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                cancelUpdate={cancelUpdate}
            />
        </div>
    );
}
