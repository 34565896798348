import { CustomerGridActions } from './customer-grid-actions';
import React from 'react';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';

export const CustomerColumns = (reloadGridCallback) => {
    return [
        { field: 'id', editable: false, hide: true, filterable: false },
        { field: 'emailAddressVerified', editable: false, hide: true, filterable: false },
        { field: 'externalUserId', editable: false, hide: true, filterable: false },
        { field: 'couchUserAccessKey', editable: false, hide: true, filterable: false },
        { field: 'updatedAt', editable: false, hide: true, filterable: false },
        { field: 'termsAccepted', editable: false, hide: true, filterable: false },
        { field: 'metadata', editable: false, hide: true, filterable: false },
        { field: 'deviceIds', editable: false, hide: true, filterable: false },
        { field: 'accountState', editable: false, hide: true, filterable: false },
        {
            field: 'nigelId',
            editable: false,
            width: 150,
            headerName: 'nigel ID',
            disableColumnMenu: true,
            filterable: false,
            sortable: false
        },
        {
            field: 'customerId',
            editable: false,
            width: 150,
            headerName: 'Customer ID',
            disableColumnMenu: true
        },
        {
            field: 'firstName',
            editable: false,
            width: 150,
            headerName: 'First Name',
            disableColumnMenu: true
        },
        {
            field: 'lastName',
            editable: true,
            width: 150,
            headerName: 'Last Name',
            disableColumnMenu: true
        },
        {
            field: 'emailAddress',
            editable: false,
            disableColumnMenu: true,
            width: 150,
            headerName: 'Email'
        },
        {
            field: 'mobileNumber',
            editable: false,
            width: 150,
            headerName: 'Mobile Number',
            disableColumnMenu: true,
            sortable: false,
            filterable: true,
            valueFormatter: (params) => {
                const phoneNumber = parsePhoneNumber(params.value ?? '');
                if (phoneNumber) {
                    return phoneNumber.formatInternational();
                }
                return params.value; // invalid number but display regardless
            }
        },
        {
            field: 'createdAt',
            editable: false,
            width: 120,
            headerName: 'Date Added',
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            type: Date,
            valueFormatter: (params) => {
                return moment(params.value).format('DD/MM/YYYY');
            }
        },
        {
            field: 'provisioned',
            editable: false,
            width: 120,
            headerName: 'is Active?',
            disableColumnMenu: true,
            sortable: true,
            filterable: true,
            align: 'center',
            headerAlign: 'center',
            type: 'boolean',
            renderCell: (params) => {
                return <div>{params.value ? 'Yes' : 'No'}</div>;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => {
                return <CustomerGridActions reloadGrid={reloadGridCallback} params={params} />;
            },
            sortable: false,
            width: 100,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true
        }
    ];
};
