import moment from 'moment';
import { v4 } from 'uuid';
import { httpService } from './http.service';
import { decrypt } from '@entelectsoftwaresa/rgaxuk-encryption';

async function uploadPartnerFile(file, partnerId, sharedBy, customers, externalUserId) {
    const dateNow = Date.now();
    file.name = file.name || `IMG-${moment(dateNow).format('DDMMYY-hhmm')}`;
    const reader = new FileReader();
    reader.readAsDataURL(file.file);
    reader.onload = async function() {
        file.data = reader.result;
        const updatedFileMetadata = await createFileMetadata(file, partnerId, externalUserId);

        return await uploadFile(updatedFileMetadata, sharedBy, 'partner-documents', customers);
    };

    reader.onerror = function(error) {
        throw error;
    };
}

async function createFileMetadata(file, partnerId, externalUserId) {
    const { name, size, type, data } = file;
    const blobFileId = `${v4()}`;
    const fileMetadata = {
        name: name,
        size: size,
        type,
        blobFileId,
        data,
        created: moment().toISOString(),
        updated: moment().toISOString(),
        partnerId,
        externalUserId
    };

    return fileMetadata;
}

async function uploadFile(fileMetadata, sharedBy, uploadUrl, customers) {
    const { blobFileId } = fileMetadata;
    const payload = {
        name: 'file',
        filename: blobFileId,
        fileMetadata,
        data: fileMetadata.data,
        sharedBy,
        customers
    };
    const res = await httpService.post(uploadUrl, payload);
    return res;
}

async function getFile(blobFileId, privateMetadataId) {
    const encryptedData = await downloadBlobFile(blobFileId);
    return decryptBlobFile(encryptedData, privateMetadataId, blobFileId);
}

async function downloadBlobFile(blobFileId) {
    const url = `blobDownload?blobFileId=${blobFileId}`;
    const response = await httpService.get(url);
    return response;
}

async function decryptBlobFile(encryptedData, privateMetadataId, blobFileId) {
    const { secretKey, checksum } = await getPrivateMetaKeys(privateMetadataId, blobFileId);
    const decryptedFile = decrypt(encryptedData, secretKey, checksum);
    return decryptedFile;
}

async function getPrivateMetaKeys(privateMetadataId, blobFileId) {
    const url = `private-metadata/${privateMetadataId}?blobFileId=${blobFileId}`;
    const res = await httpService.get(url);
    return res;
}

export const fileService = {
    uploadPartnerFile,
    getFile
};
