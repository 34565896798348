import { GATEWAY_API_BASE_URL } from '../config';
import { getTokenFromLocalStorage, LOCAL_STORAGE_KEYS } from '../utils/localStorage';
import axios from 'axios';

function createRequestHeader(idToken) {
    return {
        Authorization: `Bearer ${idToken}`
    };
}

function getToken() {
    const token = getTokenFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
    return token;
}

function getIdToken() {
    return getTokenFromLocalStorage(LOCAL_STORAGE_KEYS.ID_TOKEN);
}

async function updateProfile(_id, externalUserId, emailAddress, password) {
    const url = `${GATEWAY_API_BASE_URL}/password/${_id}`;
    const requestConfig = { headers: createRequestHeader(await getToken()) };
    const data = {
        emailAddress,
        externalUserId,
        password
    };

    return await axios.put(url, data, requestConfig);
}

async function isAuthTokenExpired() {
    const itemStr = localStorage.getItem(LOCAL_STORAGE_KEYS.nigel_partner_user_token);
    if (!itemStr) {
        return true;
    }
    const item = JSON.parse(itemStr);
    if (Date.now() >= item.value.expiry * 1000) {
        return true;
    }
    return false;
}

export const authenticationService = {
    getToken,
    getIdToken,
    updateProfile,
    isAuthTokenExpired
};
