import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styles } from './update-password-modal.styles';
import { UpdatePasswordForm } from './update-password-form/update-password-form.container';
import { notifySuccess, notifyError } from '../../../services/toast.service';

export function UpdatePasswordModal({ isModalOpen, cancelUpdate, closeModal }) {
    const handleSuccess = () => {
        closeModal();
        notifySuccess('Password was successfully updated!');
    };

    const handleError = () => {
        notifyError('Something went wrong while updating your password, try again!');
    };

    return (
        <div>
            <Dialog
                container={() => document.getElementById('app-layout')}
                maxWidth={'sm'}
                fullWidth={true}
                aria-labelledby="password-update"
                open={isModalOpen}
            >
                <IconButton onClick={cancelUpdate} style={styles.cancelIcon}>
                    <CloseIcon fontSize="small" />
                </IconButton>
                <MuiDialogContent style={styles.dialog}>
                    <UpdatePasswordForm
                        postUpdateAction={handleSuccess}
                        handleUpdateError={handleError}
                    />
                </MuiDialogContent>
            </Dialog>
        </div>
    );
}

UpdatePasswordModal.propTypes = {
    isModalOpen: PropTypes.bool,
    cancelUpdate: PropTypes.func,
    closeModal: PropTypes.func
};
