export const LOCAL_STORAGE_KEYS = Object.freeze({
    TOKEN: 'nigel_partner_user_token',
    ID_TOKEN: 'nigel_partner_user_id_token'
});

export function saveTokenToLocalStorage(key, value, ttl) {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
}

export function getTokenFromLocalStorage(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    return item.value;
}
