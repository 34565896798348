import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { colors } from '../../assets/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // TODO: Log to app insights
        // eslint-disable-next-line no-console
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <Grid>
                        <Grid item xs={12}>
                            <Typography align="center" variant="h5" component="div">
                                <FontAwesomeIcon icon="bug" size="10x" color={colors.navyOne} />
                            </Typography>
                            <div style={{ height: '100px' }}></div>
                            <Typography align="center" variant="h5" component="h5">
                                something wen't wrong, please contact support!
                            </Typography>
                            <Typography align="center" variant="h6" component="h6">
                                go back to the
                                <Link to="/home" replace>
                                    {' '}
                                    home{' '}
                                </Link>
                                page
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            );
        }
        return this.props.children;
    }
}

ErrorBoundary.prototypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element
    ]).isRequired
};
