import { isNil } from 'lodash';
import { UserProfileGridActions } from './user-profile-grid-actions';
import React from 'react';

export const columnDefinitions = (reRenderCallback) => {
    return [
        { field: 'id', editable: false, hide: true, filterable: false },
        { field: 'mobileNumber', editable: false, hide: true, filterable: false },
        { field: 'emailAddressVerified', editable: false, hide: true, filterable: false },
        { field: 'externalUserId', editable: false, hide: true, filterable: false },
        { field: 'nigelId', editable: false, hide: true, filterable: false },
        { field: 'updatedAt', editable: false, hide: true, filterable: false },
        { field: 'createdAt', editable: false, hide: true, filterable: false },
        { field: 'accountState', editable: false, hide: true, filterable: false },
        {
            field: 'firstName',
            editable: false,
            width: 200,
            headerName: 'First Name',
            disableColumnMenu: true
        },
        {
            field: 'lastName',
            editable: true,
            width: 200,
            headerName: 'Last Name',
            disableColumnMenu: true
        },
        {
            field: 'emailAddress',
            editable: false,
            disableColumnMenu: true,
            width: 200,
            headerName: 'Email'
        },
        {
            field: 'isActive',
            editable: false,
            width: 200,
            headerName: 'is Active?',
            disableColumnMenu: true,
            type: 'boolean',
            renderCell: (params) => {
                return <div>{params.value ? 'Yes' : 'No'}</div>;
            }
        },
        {
            field: 'roles',
            editable: false,
            width: 200,
            headerName: 'Roles',
            disableColumnMenu: true,
            type: Array,
            valueFormatter: (params) => {
                return `${isNil(params.value) ? 0 : params.value.length} roles`;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => {
                return <UserProfileGridActions reloadGrid={reRenderCallback} params={params} />;
            },
            sortable: false,
            width: 100,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true
        }
    ];
};
