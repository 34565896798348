import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box } from '@material-ui/core';
import { colors } from '../../assets/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styles } from './dashboard-button.styles';

export function DashboardButton({ icon, buttonText, onClick }) {
    return (
        <Button variant="text" style={styles.dashboardButton} onClick={onClick}>
            <Box sx={{ display: 'flex', p: 1 }}>
                <Box sx={{ p: 1, width: '60%' }}>
                    <FontAwesomeIcon icon={icon} size="5x" color={colors.navyOne} />
                </Box>
                <Box sx={{ p: 1, flexShrink: 1 }}>
                    <Typography variant="h5" style={styles.text}>
                        {buttonText}
                    </Typography>
                </Box>
            </Box>
        </Button>
    );
}

DashboardButton.propTypes = {
    icon: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func
};
