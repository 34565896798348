import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './customer-grid.styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { colors } from '../../../../assets/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NegativeActionModal } from '../../../../components/negative-action-modal/negative-action-modal.element';
import { customerService } from '../../../../services/';
import { Spinner } from '../../../../components/spinner/spinner';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import { hasPermission } from '../../../../utils/permission-checker';
import { notifyError } from '../../../../services/toast.service';

export function CustomerGridActions(props) {
    const [isUnlinking, setIsUnlinking] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { params, reloadGrid } = props;
    const classes = useStyles();

    const editAction = (event) => {
        event.stopPropagation();
        // todo: goto Edit page
    };

    const unlinkAction = (event) => {
        event.stopPropagation();
        setIsUnlinking(true);
    };

    const closeUnlinkingModal = () => {
        setIsUnlinking(false);
    };

    const unlinkCustomer = () => {
        const { row } = params;
        setIsLoading(true);
        customerService
            .unlinkCustomer(row.id)
            .then(() => {
                setIsLoading(false);
                reloadGrid();
            })
            .catch(() => {
                notifyError(`Something went wrong while deleting ${row.firstName} ${row.lastName}`);
                setIsLoading(false);
            });
        closeUnlinkingModal();
    };

    const disabled = !hasPermission(PERMISSIONS.permissions.EditCustomers);

    return (
        <div className={classes.iconRoot}>
            <Spinner loading={isLoading} />
            <NegativeActionModal
                open={isUnlinking}
                handleClose={closeUnlinkingModal}
                handleAction={unlinkCustomer}
                mainText={[
                    'You are about to delete',
                    `${params.row.firstName} ${params.row.lastName}`
                ]}
                subText={['You will no longer be able to', 'interact with this customer']}
                buttonText1="Cancel"
                buttonText2="Delete"
            />
            <Tooltip title="Edit customer" placement="top">
                <span>
                    <IconButton
                        disabled={disabled}
                        size="small"
                        aria-label="edit"
                        onClick={editAction}
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'pencil-alt']}
                            color={disabled ? colors.greyOne : colors.turquoiseOne}
                        />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Unlink customer">
                <span>
                    <IconButton
                        disabled={disabled}
                        size="small"
                        aria-label="unlink"
                        onClick={unlinkAction}
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'unlink']}
                            color={disabled ? colors.greyOne : colors.hotPinkOne}
                        />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    );
}

CustomerGridActions.propTypes = {
    params: PropTypes.object,
    handleDeleteClick: PropTypes.func,
    handleEditClick: PropTypes.func,
    reloadGrid: PropTypes.func
};
