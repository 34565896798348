import React from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    ListItem,
    Divider,
    List,
    IconButton,
    Grid
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../../assets/styles/colors';
import { styles } from './document-metadata-preview.styles';
import { uuid } from 'uuidv4';
import { FILE_STATUSES } from '@entelectsoftwaresa/rgaxuk-common-enums';
import moment from 'moment';
import { DATE_FORMATS } from '../../../../utils/date-format.enum';

export function AccordionItem({
    ocrEntity,
    openDataChangeModal,
    setOcrEntityToAdd,
    ocrDataToEdit,
    setOcrDataToEdit,
    setIsEditing,
    openDeleteModal,
    setOcrDataToDelete,
    status
}) {
    const { accordionTitle, accordionTitleIcon, data } = ocrEntity;

    const edit = (index, text) => {
        setIsEditing(true);
        switch (accordionTitle) {
            case 'Emails':
                setOcrDataToEdit({
                    ...ocrDataToEdit,
                    email: text,
                    index
                });
                break;
            case 'Dates':
                setOcrDataToEdit({
                    ...ocrDataToEdit,
                    date: text,
                    index
                });
                break;
            case 'Phone Numbers':
                setOcrDataToEdit({
                    ...ocrDataToEdit,
                    phoneNumber: text,
                    index
                });
                break;
            case 'Websites':
                setOcrDataToEdit({
                    ...ocrDataToEdit,
                    index,
                    url: text
                });
                break;

            default:
                break;
        }

        setOcrEntityToAdd(accordionTitle);
        openDataChangeModal();
    };

    const remove = (text) => {
        setOcrDataToDelete({
            ocrEntity: accordionTitle,
            data: text
        });
        openDeleteModal();
    };

    const add = () => {
        setOcrEntityToAdd(accordionTitle);
        openDataChangeModal();
    };

    const dataAction = (e) => {
        switch (accordionTitle) {
            case 'Emails':
                {
                    const windowRef = window.open(`mailto:${e.target.innerText}`, '_blank');
                    windowRef.focus();

                    setTimeout(function () {
                        if (!windowRef.document.hasFocus()) {
                            windowRef.close();
                        }
                    }, 500);
                }
                break;
            case 'Websites':
                window.open(e.target.innerText, e.target.innerText).focus();
                break;
            case 'Phone Numbers':
            case 'Dates':
            default:
                break;
        }
    };

    const AccordionListItem = (text, index) => {
        return (
            <>
                <ListItem key={uuid()}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Grid item xs={10}>
                            <Typography onClick={dataAction} style={styles.dataText}>
                                {accordionTitle === 'Dates'
                                    ? moment(text, DATE_FORMATS.StoredDate).format(
                                          DATE_FORMATS.FriendlyDate
                                      )
                                    : text}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={styles.dataActions}>
                            <IconButton onClick={() => edit(index, text)} edge="end">
                                <FontAwesomeIcon
                                    icon={['fas', 'pencil-alt']}
                                    color={colors.turquoiseOne}
                                    size="xs"
                                />
                            </IconButton>
                            <IconButton onClick={() => remove(text)} edge="end">
                                <FontAwesomeIcon
                                    icon={['fas', 'trash-alt']}
                                    color={colors.hotPinkOne}
                                    size="xs"
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                </ListItem>
                <Divider />
            </>
        );
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={
                    <FontAwesomeIcon
                        icon={['fas', 'chevron-down']}
                        color={colors.grayTwo100}
                        style={{ marginRight: 2 }}
                        size="sm"
                    />
                }
                style={{ display: 'flex', flexDirection: 'row' }}
            >
                <FontAwesomeIcon style={{ marginRight: 20 }} icon={accordionTitleIcon} size="2x" />
                <Typography style={styles.mt3}>{`${accordionTitle} (${data?.length})`}</Typography>
                <IconButton
                    onClick={add}
                    edge="end"
                    style={styles.plusIcon}
                    disabled={status !== FILE_STATUSES.allProcessingComplete}
                >
                    <FontAwesomeIcon
                        icon={['fas', 'plus']}
                        color={
                            status === FILE_STATUSES.allProcessingComplete
                                ? colors.turquoiseOne
                                : colors.lightBlueGray100
                        }
                        size="sm"
                    />
                </IconButton>
            </AccordionSummary>
            <AccordionDetails>
                <List style={styles.dataWidth}>
                    <Divider />
                    {data.map((item, index) => AccordionListItem(item, index))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
}

AccordionItem.propTypes = {
    ocrEntity: PropTypes.object,
    openDataChangeModal: PropTypes.func,
    openDeleteModal: PropTypes.func,
    setIsEditing: PropTypes.func,
    setOcrDataToEdit: PropTypes.func,
    setOcrDataToDelete: PropTypes.func,
    setOcrEntityToAdd: PropTypes.func.isRequired,
    ocrDataToEdit: PropTypes.object,
    status: PropTypes.string
};
