import React from 'react';
import { Container, Toolbar, Typography, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    footer: {
        top: 'auto',
        bottom: 0,
        backgroundColor: '#fff'
    },
    text: {
        textAlign: 'center'
    }
}));

export function Footer() {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.footer}>
            <Container maxWidth="md">
                <Toolbar
                    style={{
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}
                >
                    <Typography variant="body1" color="primary" className={classes.text}>
                        © {new Date().getFullYear()} nigel portal
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
