import React, { useEffect, useState } from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import {
    Button,
    Container,
    Dialog,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { colors } from '../../../../assets/styles/colors';
import { MobileNumberInput } from '../../../../components/mobile-number-input/mobile-number-input.container';
import { useLocation } from 'react-router-dom';
import { documentService } from '../../../../services';
import * as EmailValidator from 'email-validator';
import { isValidURL } from '../../../../utils/validate-url';
import { isEmpty } from 'lodash';
import { notifyError, notifySuccess } from '../../../../services/toast.service';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DATE_FORMATS } from '../../../../utils/date-format.enum';

export function MetadataModificationModal({
    isEditing,
    handleClose,
    open,
    ocrEntity,
    oldData = { date: '', email: '', url: '', phoneNumber: '' }
}) {
    const [date, setDate] = useState(oldData.date);
    const [email, setEmail] = useState(oldData.email);
    const [site, setSite] = useState(oldData.url);
    const [number, setNumber] = useState(oldData.phoneNumber.substring(3));
    const [sanitizedNumber, setSanitizedNumber] = useState(null);
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidSite, setIsValidSite] = useState(false);
    const [isValidDate, setIsValidDate] = useState(true);

    const location = useLocation();
    const [metadata, setMetadata] = useState(null);

    useEffect(() => {
        if (location && location.state) {
            const { metadata } = location.state;
            if (metadata != null) {
                setMetadata(metadata);
            }
            initialSetAndValidation(oldData);
        }
    }, [location, oldData]);

    const initialSetAndValidation = (oldData) => {
        onChangeDate(
            isEditing
                ? moment(oldData.date, DATE_FORMATS.StoredDate).format(DATE_FORMATS.FriendlyDate)
                : new Date()
        );
        onChangeEmail(oldData.email);
        onChangeSite(oldData.url);
        setNumber(oldData.phoneNumber.substring(3));
        setIsValidNumber(oldData.phoneNumber.length > 0);
    };

    const submitData = async () => {
        if (metadata !== null) {
            const { internalOcr } = metadata;
            const updatedOcr = { ...internalOcr };
            if (isEditing) {
                switch (ocrEntity) {
                    case 'Emails':
                        updatedOcr.emails[oldData.index].name = email;
                        break;
                    case 'Dates':
                        updatedOcr.dates[oldData.index].name = moment(
                            date,
                            DATE_FORMATS.FriendlyDate
                        ).format(DATE_FORMATS.StoredDate);
                        break;
                    case 'Phone Numbers':
                        updatedOcr.phoneNumbers[oldData.index].name = sanitizedNumber;
                        break;
                    case 'Websites':
                        updatedOcr.urls[oldData.index].name = site;
                        break;
                    default:
                        break;
                }
            } else {
                switch (ocrEntity) {
                    case 'Emails':
                        updatedOcr.emails
                            ? updatedOcr.emails.push({
                                  averageEntityTypeScore: 1,
                                  matches: [],
                                  name: email,
                                  type: 'Email'
                              })
                            : (updatedOcr.emails = [
                                  {
                                      averageEntityTypeScore: 1,
                                      matches: [],
                                      name: email,
                                      type: 'Email'
                                  }
                              ]);
                        break;
                    case 'Dates':
                        updatedOcr.dates
                            ? updatedOcr.dates.push({
                                  averageEntityTypeScore: 1,
                                  matches: [],
                                  name: moment(date, DATE_FORMATS.FriendlyDate).format(
                                      DATE_FORMATS.StoredDate
                                  ),
                                  type: 'Date'
                              })
                            : (updatedOcr.dates = [
                                  {
                                      averageEntityTypeScore: 1,
                                      matches: [],
                                      name: moment(date, DATE_FORMATS.FriendlyDate).format(
                                          DATE_FORMATS.StoredDate
                                      ),
                                      type: 'Date'
                                  }
                              ]);
                        break;
                    case 'Phone Numbers':
                        updatedOcr.phoneNumbers
                            ? updatedOcr.phoneNumbers.push({
                                  averageEntityTypeScore: 1,
                                  matches: [],
                                  name: sanitizedNumber,
                                  type: 'Phone Number'
                              })
                            : (updatedOcr.phoneNumbers = [
                                  {
                                      averageEntityTypeScore: 1,
                                      matches: [],
                                      name: sanitizedNumber,
                                      type: 'Phone Number'
                                  }
                              ]);
                        break;
                    case 'Websites':
                        updatedOcr.urls
                            ? updatedOcr.urls.push({
                                  averageEntityTypeScore: 1,
                                  matches: [],
                                  name: site,
                                  type: 'Url'
                              })
                            : (updatedOcr.urls = [
                                  {
                                      averageEntityTypeScore: 1,
                                      matches: [],
                                      name: site,
                                      type: 'Url'
                                  }
                              ]);
                        break;

                    default:
                        break;
                }
            }

            documentService
                .modifyDocument(metadata.id, { internalOcr: updatedOcr })
                .then(() => {
                    notifySuccess(
                        `${ocrEntity.slice(0, -1)} ${isEditing ? 'updated' : 'added'} successfully`
                    );
                    onCloseModal();
                })
                .catch(() => {
                    notifyError(
                        `Something went wrong while updating the ${ocrEntity
                            .slice(0, -1)
                            .toLowerCase()}`
                    );
                });
        }
    };

    const onChangeDate = (date) => {
        setDate(date);
        setIsValidDate(true);
    };

    const onChangeEmail = (email) => {
        setIsValidEmail(EmailValidator.validate(email));
        setEmail(email);
    };

    const onChangeSite = (url) => {
        setSite(url);
        setIsValidSite(isValidURL(url));
    };

    const isValidForm = () => {
        let isValid = false;
        switch (ocrEntity) {
            case 'Emails':
                isValid = !isValidEmail;
                break;
            case 'Dates':
                isValid = !isValidDate;
                break;
            case 'Phone Numbers':
                isValid = !isValidNumber;
                break;
            case 'Websites':
                isValid = !isValidSite;
                break;

            default:
                isValid = false;
                break;
        }
        return isValid;
    };

    const dateForm = () => {
        return (
            <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        label="Date"
                        value={date}
                        onChange={onChangeDate}
                        format={DATE_FORMATS.DatePickerDate}
                        inputVariant="outlined"
                        okLabel={
                            <Button color="secondary" variant="contained" disableElevation>
                                Ok
                            </Button>
                        }
                        cancelLabel={
                            <Button color="secondary" variant="outlined" disableElevation>
                                Cancel
                            </Button>
                        }
                        error={!isValidDate && !isEmpty(date)}
                        helperText={
                            !isValidDate && !isEmpty(date) ? 'Please enter a valid date.' : ''
                        }
                    />
                </MuiPickersUtilsProvider>
            </>
        );
    };

    const emailForm = () => {
        return (
            <>
                <TextField
                    variant="outlined"
                    id="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={({ target }) => onChangeEmail(target.value)}
                    error={!isValidEmail && !isEmpty(email)}
                    helperText={
                        !isValidEmail && !isEmpty(email)
                            ? 'Please enter a valid email address.'
                            : ''
                    }
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </>
        );
    };

    const siteForm = () => {
        return (
            <>
                <TextField
                    variant="outlined"
                    id="site"
                    label="Web site"
                    type="text"
                    value={site}
                    onChange={({ target }) => onChangeSite(target.value)}
                    error={!isValidSite && !isEmpty(site)}
                    helperText={
                        !isValidSite && !isEmpty(site) ? 'Please enter a valid website.' : ''
                    }
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </>
        );
    };

    const numberForm = () => {
        return (
            <>
                <MobileNumberInput
                    number={number}
                    setNumber={setNumber}
                    setIsValid={setIsValidNumber}
                    isValid={isValidNumber}
                    setSanitizedNumber={setSanitizedNumber}
                />
            </>
        );
    };

    const onCloseModal = () => {
        setDate(new Date());
        setEmail('');
        setSite('');
        setNumber('');
        setIsValidNumber(false);
        setIsValidDate(false);
        setIsValidEmail(false);
        setIsValidSite(false);
        handleClose();
    };

    const renderFormContent = () => {
        if (ocrEntity === 'Dates') {
            return dateForm();
        } else if (ocrEntity === 'Emails') {
            return emailForm();
        } else if (ocrEntity === 'Websites') {
            return siteForm();
        } else if (ocrEntity === 'Phone Numbers') {
            return numberForm();
        } else {
            return <>Something</>;
        }
    };

    return (
        <Dialog
            container={() => document.getElementById('app-layout')}
            aria-labelledby="metadata-update-action"
            open={open}
            maxWidth={'md'}
            fullWidth={true}
        >
            <Container>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography style={{ marginTop: 10 }} variant="h5">
                        {`${isEditing ? 'Edit' : 'Add new'} ${ocrEntity
                            .slice(0, -1)
                            .toLowerCase()}`}
                    </Typography>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton onClick={onCloseModal}>
                            <FontAwesomeIcon
                                icon={['fas', 'times']}
                                color={colors.grayTwo100}
                                size="sm"
                            />
                        </IconButton>
                    </div>
                </div>

                <MuiDialogContent>
                    <Grid spacing={3} direction="column" container>
                        {renderFormContent()}
                    </Grid>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button
                        size="large"
                        variant="outlined"
                        color="secondary"
                        onClick={onCloseModal}
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={submitData}
                        disabled={isValidForm()}
                    >
                        {`${isEditing ? 'Edit' : 'Add'} ${ocrEntity.slice(0, -1).toLowerCase()}`}
                    </Button>
                </MuiDialogActions>
            </Container>
        </Dialog>
    );
}

MetadataModificationModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    ocrEntity: PropTypes.string.isRequired,
    oldData: PropTypes.object,
    isEditing: PropTypes.bool
};
