import { toast } from 'react-toastify';

const toastConfiguration = {
    autoClose: 10000,
    draggable: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    pauseOnHover: true,
    closeOnClick: true,
    hideProgressBar: false,
    newestOnTop: true,
    theme: 'colored'
};

export const notifyError = message => {
    toast.error(message, toastConfiguration);
};

export const notifyWarning = message => {
    toast.warning(message, toastConfiguration);
};

export const notifyInfo = message => {
    toast.info(message, toastConfiguration);
};

export const notifySuccess = message => {
    toast.success(message, toastConfiguration);
};
