/* eslint-disable react/display-name */
import { isNil } from 'lodash';
import { RoleGridActions } from './role-grid-actions';
import React from 'react';

export const columnDefinitions = (reRenderCallback) => {
    return [
        { field: 'id', editable: false, hide: true, filterable: false },
        {
            field: 'roleName',
            editable: false,
            width: 200,
            headerName: 'Role Name',
            disableColumnMenu: true
        },
        {
            field: 'roleDescription',
            editable: false,
            width: 400,
            headerName: 'Role Description',
            disableColumnMenu: true
        },
        {
            field: 'isActive',
            editable: false,
            width: 200,
            headerName: 'is Active',
            disableColumnMenu: true,
            type: 'boolean',
            renderCell: (params) => {
                return <div>{params.value ? 'Yes' : 'No'}</div>;
            }
        },
        {
            field: 'permissions',
            editable: false,
            width: 200,
            headerName: 'Permissions',
            disableColumnMenu: true,
            type: Array,
            valueFormatter: (params) => {
                return `${isNil(params.value) ? 0 : params.value.length} permissions`;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => {
                return <RoleGridActions reloadGrid={reRenderCallback} params={params} />;
            },
            sortable: false,
            width: 100,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true
        }
    ];
};
