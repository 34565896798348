import { formatData, formatFilterForRequest } from '../utils/data-table-helpers';
import { httpService } from './http.service';

async function addUser(user) {
    const url = `portal-user-profiles/`;
    return await httpService.post(url, user);
}

async function editUser(user) {
    const url = `portal-user-profiles/${user._id}`;
    return await httpService.patch(url, user);
}

async function getUser(externalUserId) {
    const url = `portal-user-profiles/?externalUserId=${externalUserId}`;
    const response = await httpService.get(url);
    return await response.data[0];
}

async function getUserById(id) {
    const url = `portal-user-profiles/?_id=${id}`;
    const response = await httpService.get(url);
    return await response.data[0];
}

async function getUsers(options) {
    const { pageSize, pageNumber, sortBy, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `portal-user-profiles?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[${
        sortBy.field
    }]=${sortBy.sort === 'asc' ? 1 : -1}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getUsersBySearch(options) {
    const { pageSize, pageNumber, searchText, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `portal-user-profiles?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[firstName]=1&$or[0][emailAddress][$search]=${searchText}&$or[1][lastName][$search]=${searchText}&$or[2][firstName][$search]=${searchText}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getUsersByFilter(options) {
    const { pageSize, pageNumber, filter, partnerId } = options;
    const skip = pageSize * pageNumber;
    const filterQuery = formatFilterForRequest(filter);

    const url = `portal-user-profiles?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[firstName]=1&${filterQuery}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function setUserIsActive(id, state) {
    const url = `portal-user-profiles/${id}`;
    const response = await httpService.patch(url, {
        isActive: state
    });
    return await response.data;
}

export const userProfileService = {
    addUser,
    editUser,
    getUser,
    getUserById,
    getUsers,
    getUsersBySearch,
    getUsersByFilter,
    setUserIsActive
};
