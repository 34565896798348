import React from 'react';
import PropTypes from 'prop-types';
import { UserForm } from './user-form.container';
import { userProfileService } from '../../../../services';
import { useHistory } from 'react-router';
import { notifyError, notifySuccess } from '../../../../services/toast.service';

export function EditUserForm(props) {
    const {
        userId,
        firstName,
        lastName,
        email,
        active,
        userRoles,
        setFirstName,
        setLastName,
        setEmail,
        setActive,
        setUserRoles,
        loading,
        setLoading
    } = props;

    const history = useHistory();

    const submitUser = async (event) => {
        setLoading(true);

        event.preventDefault();

        const user = {
            _id: userId,
            firstName,
            lastName,
            emailAddress: email,
            isActive: active,
            roles: userRoles
        };

        userProfileService
            .editUser(user)
            .then(() => {
                setLoading(false);
                notifySuccess(`${firstName} ${lastName} successfully edited.`);
                gotoUsersPage();
            })
            .catch(() => {
                setLoading(false);
                notifyError(`Something went wrong while editing ${firstName} ${lastName}`);
            });
    };

    const gotoUsersPage = () => {
        history.push('/users');
    };

    return (
        <div>
            <UserForm
                isEdit={true}
                firstName={firstName}
                lastName={lastName}
                email={email}
                active={active}
                userRoles={userRoles}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setActive={setActive}
                setUserRoles={setUserRoles}
                loading={loading}
                submitUser={submitUser}
                onCancel={gotoUsersPage}
            />
        </div>
    );
}

EditUserForm.propTypes = {
    openSuccessModel: PropTypes.func,
    userId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    number: PropTypes.string,
    active: PropTypes.bool,
    userRoles: PropTypes.array,
    setFirstName: PropTypes.func,
    setLastName: PropTypes.func,
    setEmail: PropTypes.func,
    setNumber: PropTypes.func,
    setActive: PropTypes.func,
    setUserRoles: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func
};
