import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUsersCog,
    faUserPlus,
    faIdBadge,
    faShareSquare,
    faChartPie,
    faTasks,
    faHome,
    faQuestionCircle,
    faChevronRight,
    faExclamationCircle,
    faPencilAlt,
    faTrashAlt,
    faUsers,
    faUnlink,
    faCloudDownloadAlt,
    faUpload,
    faBan,
    faSync,
    faEye,
    faAt,
    faCalendar,
    faGlobe,
    faPhone,
    faFileDownload,
    faBullhorn,
    faUserFriends,
    faForward,
    faBackward,
    faFile,
    faSearchPlus,
    faSearchMinus,
    faUndo,
    faRedo,
    faPlus,
    faChevronDown,
    faTimes,
    faBug,
    faSearch
} from '@fortawesome/free-solid-svg-icons';

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

// add all the icons we want to use in app here
library.add(
    faUsersCog,
    faUserPlus,
    faIdBadge,
    faShareSquare,
    faChartPie,
    faTasks,
    faHome,
    faQuestionCircle,
    faChevronRight,
    faCheckCircle,
    faExclamationCircle,
    faPencilAlt,
    faTrashAlt,
    faUsers,
    faUnlink,
    faCloudDownloadAlt,
    faUpload,
    faBan,
    faSync,
    faEye,
    faAt,
    faCalendar,
    faGlobe,
    faPhone,
    faFileDownload,
    faBullhorn,
    faUserFriends,
    faForward,
    faBackward,
    faFile,
    faSearchPlus,
    faSearchMinus,
    faUndo,
    faRedo,
    faPlus,
    faChevronDown,
    faTimes,
    faBug,
    faSearch
);
