import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { GridToolbarFilterButton } from '@material-ui/data-grid';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { styles, useStyles } from './document-grid.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../../assets/styles/colors';

export function DocumentGridToolBar(props) {
    const classes = useStyles();
    const { clearSearch, onChange, value, handleDataRefresh } = props;
    return (
        <div className={classes.root}>
            <div>
                <Typography variant="h5" component="span" style={styles.gridHeader}>
                    Existing Documents
                </Typography>
            </div>
            <div>
                <span style={{ marginRight: 10 }}>
                    <Tooltip title="Refresh data" placement="bottom">
                        <span style={{ marginTop: 15 }}>
                            <IconButton
                                size="small"
                                aria-label="refresh-data"
                                onClick={handleDataRefresh}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'sync']}
                                    color={colors.turquoiseTwo}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </span>
                <GridToolbarFilterButton style={styles.filterSpacing} />
                <TextField
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    placeholder="Find..."
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: value ? 'visible' : 'hidden' }}
                                onClick={clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        )
                    }}
                />
            </div>
        </div>
    );
}

DocumentGridToolBar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    handleDataRefresh: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    classes: PropTypes.object
};
