import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, TextField, Typography } from '@material-ui/core';
import { GridToolbarFilterButton } from '@material-ui/data-grid';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { styles, useStyles } from './marketing-messages-grid.styles';

export function MarketingMessagesGridToolBar(props) {
    const classes = useStyles();
    const { clearSearch, onChange, value } = props;
    return (
        <div className={classes.root}>
            <div>
                <Typography variant="h5" component="span" style={styles.gridHeader}>
                    Marketing Messages
                </Typography>
            </div>
            <div>
                <GridToolbarFilterButton style={styles.filterSpacing} />
                <TextField
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    placeholder="Find..."
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: value ? 'visible' : 'hidden' }}
                                onClick={clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        )
                    }}
                />
            </div>
        </div>
    );
}

MarketingMessagesGridToolBar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    classes: PropTypes.object
};
