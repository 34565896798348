export const styles = {
    btnContainer: {
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    dialog: {
        paddingTop: 15
    },
    cancelIcon: {
        alignSelf: 'flex-end',
        margin: 0,
        background: 'transparent'
    }
};
