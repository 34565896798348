import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserProfileGrid } from './user-profile-grid/user-profile-grid.container';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import { hasPermission } from '../../../utils/permission-checker';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    }
}));

export function ViewUsersPage() {
    const classes = useStyles();
    const history = useHistory();

    const goToAddUserPage = () => {
        history.push('add-user');
    };

    return (
        <Grid container spacing={1} direction="row" styles={{ marginBottom: 100 }}>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <div className={classes.root}>
                    <div style={{ padding: 20, paddingLeft: 0 }}>
                        <Typography variant="h4" component="span">
                            Manage Existing Users
                        </Typography>
                    </div>
                    {hasPermission(PERMISSIONS.permissions.CreateUsers) && (
                        <div style={{ padding: 20, paddingRight: 0, paddingLeft: 0 }}>
                            <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                onClick={goToAddUserPage}
                            >
                                Add a new user
                            </Button>
                        </div>
                    )}
                </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <UserProfileGrid />
            </Grid>
        </Grid>
    );
}
