import { Typography, Box } from '@material-ui/core';
import React from 'react';
import { colors } from '../../assets/styles/colors';

export function Banner() {
    return (
        <Box
            style={{
                height: 150,
                borderWidth: 1,
                borderColor: colors.navyOne,
                borderRadius: '24px',
                border: 'solid',
                marginTop: 10
            }}
            display="flex"
            justifyContent="center"
            p={5}
        >
            <Typography variant="h5">Content banner</Typography>
        </Box>
    );
}
