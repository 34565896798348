import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './marketing-messages-grid.styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { colors } from '../../../../assets/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PERMISSIONS } from '@entelectsoftwaresa/rgaxuk-common-enums';
import { hasPermission } from '../../../../utils/permission-checker';
import { Link } from 'react-router-dom';
import { NegativeActionModal } from '../../../../components/negative-action-modal/negative-action-modal.element';
import { Spinner } from '../../../../components/spinner/spinner';
import { marketingMessageService } from '../../../../services';
import { notifyError } from '../../../../services/toast.service';
export function MarketingMessagesGridActions(props) {
    const { params, reloadGrid } = props;
    const classes = useStyles();
    const canPreview = hasPermission(PERMISSIONS.permissions.ViewMarketing);
    const canDelete = hasPermission(PERMISSIONS.permissions.DeleteMarketing);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const deleteMarketingMessageAction = (event) => {
        event.stopPropagation();
        setIsDeleting(true);
    };

    const closeDeletingModal = () => {
        setIsDeleting(false);
    };

    const deleteMarketingMessage = () => {
        setIsLoading(true);

        marketingMessageService
            .deleteMarketingMessage(params.row.id)
            .then(() => {
                closeDeletingModal();
                setIsLoading(false);
                reloadGrid();
            })
            .catch(() => {
                closeDeletingModal();
                notifyError('Something went wrong while deleting the message, try again.');
                setIsLoading(false);
            });
    };

    return (
        <div className={classes.iconRoot}>
            <Spinner loading={isLoading} />
            <NegativeActionModal
                open={isDeleting}
                handleClose={closeDeletingModal}
                handleAction={deleteMarketingMessage}
                mainText={['You are about to delete this Marketing Message', '']}
                subText={['This Marketing Message will no longer be visible']}
                buttonText1="Cancel"
                buttonText2="Delete"
            />
            {canPreview && (
                <Tooltip title="View marketing message" placement="top">
                    <span>
                        <Link
                            to={{
                                pathname: `/${params.id}/preview-marketing-message`
                            }}
                            style={{ textDecoration: 'none' }}
                        >
                            <IconButton size="small" aria-label="view">
                                <FontAwesomeIcon
                                    icon={['fas', 'eye']}
                                    color={colors.turquoiseOne}
                                />
                            </IconButton>
                        </Link>
                    </span>
                </Tooltip>
            )}
            {canDelete && (
                <Tooltip title="Delete Marketing Message">
                    <span>
                        <IconButton
                            size="small"
                            aria-label="delete"
                            onClick={deleteMarketingMessageAction}
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'trash-alt']}
                                color={colors.hotPinkOne}
                            />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
        </div>
    );
}

MarketingMessagesGridActions.propTypes = {
    params: PropTypes.object,
    handleDeleteClick: PropTypes.func,
    handleEditClick: PropTypes.func,
    reloadGrid: PropTypes.func
};
