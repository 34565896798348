import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { styles } from './add-marketing-message-form.styles';
import { FilePickerInput } from '../file-picker/file-picker-input.element';

export function AddMarketingMessageElement(props) {
    const {
        title,
        body,
        url,
        urlText,
        titleError,
        bodyError,
        urlError,
        setTitleForValidation,
        setBodyForValidation,
        setURLValidation,
        setURLText,
        processFile,
        isEditable
    } = props;

    return (
        <div style={{ height: '100%' }}>
            <Grid container spacing={0} style={{ height: '100%' }}>
                <Grid
                    item
                    xl={8}
                    lg={8}
                    md={10}
                    sm={10}
                    xs={12}
                    container
                    direction="column"
                    style={styles.container}
                >
                    <form autoComplete="off">
                        <Box m={1} p={1}>
                            <Box>
                                <Typography style={styles.header} variant="body1" component="h6">
                                    Marketing Message Details
                                </Typography>
                                <Box display="flex" flexDirection="column">
                                    <TextField
                                        disabled={!isEditable}
                                        value={title}
                                        onChange={setTitleForValidation}
                                        label="Title"
                                        error={isEditable && titleError.length > 0}
                                        required={true}
                                        helperText={isEditable ? titleError : ''}
                                        style={styles.textField}
                                    />
                                    <TextField
                                        disabled={!isEditable}
                                        value={body}
                                        multiline
                                        rows={6}
                                        rowsMax={8}
                                        onChange={setBodyForValidation}
                                        label="Message Body"
                                        error={isEditable && bodyError.length > 0}
                                        required={true}
                                        helperText={isEditable ? bodyError : ''}
                                        style={styles.textField}
                                    />
                                    <FilePickerInput
                                        disabled={!isEditable}
                                        filter={'.jpg, .png, .jpeg, .bmp'}
                                        textStyle={styles.textField}
                                        processFile={processFile}
                                    />
                                    <TextField
                                        disabled={!isEditable}
                                        value={url}
                                        onChange={setURLValidation}
                                        label="Url Link"
                                        error={isEditable && urlError.length > 0}
                                        helperText={isEditable ? urlError : ''}
                                        style={styles.textField}
                                    />
                                    <TextField
                                        disabled={!isEditable}
                                        value={urlText}
                                        onChange={setURLText}
                                        label="Url Text"
                                        style={styles.textField}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
}

AddMarketingMessageElement.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    url: PropTypes.string,
    urlText: PropTypes.string,

    titleError: PropTypes.string,
    bodyError: PropTypes.string,
    urlError: PropTypes.string,
    submissionError: PropTypes.bool,

    setTitleForValidation: PropTypes.func,
    setBodyForValidation: PropTypes.func,
    setURLValidation: PropTypes.func,
    setURLText: PropTypes.func,
    onFormUpdate: PropTypes.func,
    closeErrorModal: PropTypes.func,
    processFile: PropTypes.func,
    isEditable: PropTypes.bool
};
