import { formatData, formatFilterForRequest } from '../utils/data-table-helpers';
import { httpService } from './http.service';

async function unlinkCustomer(userId) {
    const url = `customers/${userId}`;
    const data = { partnerId: process.env.REACT_APP_NIGEL_PARTNER_ID };

    const response = httpService.patch(url, data);
    return response;
}

async function getCustomer(externalUserId) {
    const url = `customers/?externalUserId=${externalUserId}`;
    const response = await httpService.get(url);
    return await response.data[0];
}

async function getCustomers(options) {
    const { pageSize, pageNumber, sortBy, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `customers?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[${
        sortBy.field
    }]=${sortBy.sort === 'asc' ? 1 : -1}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getCustomersBySearch(options) {
    const { pageSize, pageNumber, searchText, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `customers?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[firstName]=1&$or[0][emailAddress][$search]=${searchText}&$or[1][lastName][$search]=${searchText}&$or[2][firstName][$search]=${searchText}&$or[3][mobileNumber][$search]=${searchText}&$or[4][customerId][$search]=${searchText}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getCustomersByFilter(options) {
    const { pageSize, pageNumber, filter, partnerId } = options;
    const skip = pageSize * pageNumber;
    const filterQuery = formatFilterForRequest(filter);

    const url = `customers?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[firstName]=1&${filterQuery}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

export const customerService = {
    getCustomer,
    getCustomers,
    getCustomersBySearch,
    getCustomersByFilter,
    unlinkCustomer
};
