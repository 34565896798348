import React, { useState, useContext } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { UploadCustomerErrorModal } from './upload-customer-error-modal/upload-customer-error-modal.element';
import { DashboardButton } from '../../../components/dashboard-button/dashboard-button.element';
import { Spinner } from '../../../components/spinner/spinner';
import { FilePicker } from '../../../components/file-picker/file-picker.element';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UserProfileContext } from '../../../store/user-profile.context';
import { useHistory } from 'react-router';
import { downloadErrorReport, downloadExcelFile, processFile } from './add-customer.functions';

export function AddCustomersPage() {
    const [loading, setLoading] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [errorReportType, setErrorReportType] = useState('');
    const [errorReportData, setErrorReportData] = useState([]);
    const userProfileContext = useContext(UserProfileContext);
    const history = useHistory();

    const onFileDropped = (file) => {
        processFile({
            file,
            userProfileContext,
            history,
            setLoading,
            setSubmissionError,
            setErrorReportType,
            setErrorReportData
        });
    };

    const closeErrorModal = () => {
        setSubmissionError(false);
    };

    return (
        <Container>
            <div style={{ padding: 20, paddingLeft: 10, marginBottom: 50 }}>
                <Typography variant="h3" component="span">
                    Add new customers
                </Typography>
            </div>
            <Spinner loading={loading}></Spinner>
            <UploadCustomerErrorModal
                open={submissionError}
                handleClose={closeErrorModal}
                downloadErrorReport={() =>
                    downloadErrorReport({ errorReportData, setSubmissionError })
                }
                errorReportType={errorReportType}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <DndProvider backend={HTML5Backend}>
                        <FilePicker onDrop={onFileDropped} />
                    </DndProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <DashboardButton
                        icon="cloud-download-alt"
                        buttonText="Download Template"
                        onClick={downloadExcelFile}
                        style={{ height: 300 }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
