import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../../assets/styles/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@material-ui/icons/Close';
import { successModal as styles } from './upload-customer-error-modal.styles';

export function UploadCustomerErrorModal(props) {
    const { handleClose, open, downloadErrorReport, errorReportType } = props;

    const dialogContent = () => {
        switch (errorReportType) {
            case 'error_report':
                return (
                    <>
                        <Typography style={styles.centerSelf} variant="h6">
                            File could not be processed because
                        </Typography>
                        <Typography style={styles.centerSelf} variant="h6">
                            there are some errors in the file.
                        </Typography>
                        <Typography
                            style={{ ...styles.textMessage, marginTop: 10 }}
                            variant="subtitle1"
                            component="p"
                        >
                            Please correct the errors and upload
                        </Typography>
                        <Typography style={styles.textMessage} variant="subtitle1" component="p">
                            the file again.
                        </Typography>
                    </>
                );
            case 'file_type':
                return (
                    <>
                        <Typography style={styles.centerSelf} variant="h6">
                            Incorrect file type detected.
                        </Typography>
                        <Typography style={styles.centerSelf} variant="h6">
                            Please upload an EXCEL or CSV document
                        </Typography>
                    </>
                );
            case 'invalid_records':
                return (
                    <>
                        <Typography style={styles.centerSelf} variant="h6">
                            File could not be uploaded because
                        </Typography>
                        <Typography style={styles.centerSelf} variant="h6">
                            it is in the incorrect format.
                        </Typography>
                        <Typography
                            style={{ ...styles.textMessage, marginTop: 10 }}
                            variant="subtitle1"
                            component="p"
                        >
                            Please download the template
                        </Typography>
                        <Typography style={styles.textMessage} variant="subtitle1" component="p">
                            and try again.
                        </Typography>
                    </>
                );
            default:
                return (
                    <>
                        <Typography style={styles.centerSelf} variant="h6">
                            Something went wrong whilst
                        </Typography>
                        <Typography style={styles.centerSelf} variant="h6">
                            adding customers
                        </Typography>
                    </>
                );
        }
    };

    const dialogActions = () => {
        switch (errorReportType) {
            case 'error_report':
                return (
                    <Button
                        style={styles.btnWidth}
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={downloadErrorReport}
                    >
                        Download Error Report
                    </Button>
                );
            case 'file_type':
            case 'invalid_records':
                return (
                    <Button
                        style={styles.btnWidth}
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Upload Again
                    </Button>
                );
            default:
                return (
                    <Button
                        style={styles.btnWidth}
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Try Again
                    </Button>
                );
        }
    };

    return (
        <Dialog
            container={() => document.getElementById('app-layout')}
            aria-labelledby="successful-action"
            open={open}
        >
            <IconButton onClick={handleClose} style={styles.cancelIcon}>
                <CloseIcon fontSize="small" />
            </IconButton>
            <MuiDialogContent style={styles.dialog}>
                <Grid spacing={3} direction="column" container>
                    <FontAwesomeIcon
                        style={styles.iconStyle}
                        icon={['fas', 'exclamation-circle']}
                        color={colors.redOne}
                        size="5x"
                    />
                    {dialogContent()}
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions style={styles.btnContainer}>{dialogActions()}</MuiDialogActions>
        </Dialog>
    );
}

UploadCustomerErrorModal.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    errorReportType: PropTypes.string,
    downloadErrorReport: PropTypes.func
};
