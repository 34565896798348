import { formatData } from '../utils/data-table-helpers';
import { httpService } from './http.service';

async function addCustomers(customers) {
    const path = 'user-profiles';
    return httpService.post(path, customers);
}

async function getCustomer(externalUserId) {
    const path = `user-profiles/?externalUserId=${externalUserId}`;
    const response = await httpService.get(path);
    return await response.data[0];
}

async function getCustomers(options) {
    const { pageSize, pageNumber, sortBy } = options;
    const skip = pageSize * pageNumber;
    const path = `user-profiles?$limit=${pageSize}&$skip=${skip}&$sort[${sortBy.field}]=${
        sortBy.sort === 'asc' ? 1 : -1
    }`;
    const response = await httpService.get(path);
    return { rows: formatData(response.data), totalRows: response.total };
}

export const customerProfileService = {
    addCustomers,
    getCustomer,
    getCustomers
};
