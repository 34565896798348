import React, { useState, useContext } from 'react';
import { UserProfileContext } from '../../../store/user-profile.context';
import { SelectCustomersModal } from '../../../components/select-customers-modal/select-customers-modal.element';
import { useHistory } from 'react-router';
import { Spinner } from '../../../components/spinner/spinner';
import { FilePicker } from '../../../components/file-picker/file-picker.element';
import { Typography, Container } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { processFile, submitFile } from './add-document.functions';
import { notifyError } from '../../../services/toast.service';

export function AddDocumentPage() {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [openCustomersModal, setOpenCustomersModal] = useState(false);
    const userProfileContext = useContext(UserProfileContext);
    const partnerId = userProfileContext.userProfile.partnerId;
    const externalUserId = userProfileContext.userProfile.externalUserId;
    const history = useHistory();

    const getSelectedCustomers = (selectedCustomers) => {
        if (selectedCustomers !== null && file !== null) {
            if (selectedCustomers.length > 0) {
                submitFile({
                    file,
                    customers: selectedCustomers,
                    partnerId,
                    userProfileContext,
                    externalUserId,
                    history,
                    setLoading
                });
            } else {
                notifyError('Please select at least one customer');
            }
        }
    };

    const onFileDropped = (file) => {
        processFile({ file, setFile, setOpenCustomersModal });
    };

    return (
        <>
            <SelectCustomersModal
                openCustomersModal={openCustomersModal}
                setOpenCustomersModal={setOpenCustomersModal}
                getSelectedCustomers={getSelectedCustomers}
            />
            <Container>
                <Spinner loading={loading}></Spinner>
                <Typography
                    align="center"
                    style={{ paddingBottom: 30 }}
                    variant="h4"
                    component="h4"
                >
                    Add a new Document
                </Typography>
                <DndProvider backend={HTML5Backend}>
                    <FilePicker onDrop={onFileDropped} />
                </DndProvider>
            </Container>
        </>
    );
}
