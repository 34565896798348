import { formatData, formatFilterForRequest } from '../utils/data-table-helpers';
import { httpService } from './http.service';

async function addMarketingMessage(message) {
    await httpService.post('marketing-messages', message);
}

async function getMarketingMessage(id) {
    const url = `marketing-messages/?_id=${id}`;
    const response = await httpService.get(url);
    return await response.data[0];
}

async function getUnencryptedBlob(blobFileId) {
    const url = `blobDownload?blobFileId=${blobFileId}`;
    const response = await httpService.get(url);
    return await response;
}

async function getMarketingMessages(options) {
    const { pageSize, pageNumber, sortBy, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `marketing-messages?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[${
        sortBy.field
    }]=${sortBy.sort === 'asc' ? 1 : -1}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getMarketingMessagesBySearch(options) {
    const { pageSize, pageNumber, searchText, partnerId } = options;
    const skip = pageSize * pageNumber;

    const url = `marketing-messages?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[firstName]=1&$or[0][emailAddress][$search]=${searchText}&$or[1][lastName][$search]=${searchText}&$or[2][firstName][$search]=${searchText}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function getMarketingMessagesByFilter(options) {
    const { pageSize, pageNumber, filter, partnerId } = options;
    const skip = pageSize * pageNumber;
    const filterQuery = formatFilterForRequest(filter);

    const url = `marketing-messages?partnerId=${partnerId}&$limit=${pageSize}&$skip=${skip}&$sort[firstName]=1&${filterQuery}`;

    const response = await httpService.get(url);
    return { rows: formatData(response.data), totalRows: response.total };
}

async function deleteMarketingMessage(id) {
    const url = `marketing-messages/${id}`;

    const response = await httpService.patch(url, {
        isDeleted: true
    });
    return await response.data;
}

export const marketingMessageService = {
    addMarketingMessage,
    getMarketingMessage,
    getMarketingMessages,
    getMarketingMessagesBySearch,
    getMarketingMessagesByFilter,
    getUnencryptedBlob,
    deleteMarketingMessage
};
