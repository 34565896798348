import React, { useState, useEffect, useCallback, useContext } from 'react';
import { DocumentColumns } from './document-grid-col-def';
import { DocumentGridElement } from './document-grid.element';
import { documentService } from '../../../../services';
import { debounce } from 'lodash';
import { isEmpty, isNil } from 'lodash';
import { UserProfileContext } from '../../../../store/user-profile.context';
import { notifyError } from '../../../../services/toast.service';

const getDocuments = ({
    page,
    pageSize,
    sortModel,
    partnerId,
    searchText,
    filter,
    setIsFetching,
    setTotalRows,
    setData,
    handleError
}) => {
    const fetchOptions = {
        pageNumber: page,
        pageSize,
        sortBy: sortModel[0],
        partnerId
    };

    setIsFetching(true);

    if (!isEmpty(searchText)) {
        documentService
            .getDocumentsBySearch({ ...fetchOptions, searchText })
            .then(({ rows, totalRows }) => {
                setTotalRows(totalRows);
                setData((oldData) => ({ ...oldData, rows }));
                setIsFetching(false);
            })
            .catch(handleError);
        return;
    }

    if (!isNil(filter.items)) {
        if (!isEmpty(filter?.items[0]?.value)) {
            documentService
                .getDocumentsByFilter({ ...fetchOptions, filter: filter.items[0] })
                .then(({ rows, totalRows }) => {
                    setTotalRows(totalRows);
                    setData((oldData) => ({ ...oldData, rows }));
                    setIsFetching(false);
                })
                .catch(handleError);
            return;
        }
    }

    documentService
        .getDocuments(fetchOptions)
        .then(({ rows, totalRows }) => {
            setTotalRows(totalRows);
            setData((oldData) => ({ ...oldData, rows }));
            setIsFetching(false);
        })
        .catch(handleError);
};

export function DocumentGrid() {
    const userProfileContext = useContext(UserProfileContext);

    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [sortModel, setSortModel] = useState([
        {
            field: 'createdAt',
            sort: 'desc'
        }
    ]);
    const [filter, setFilter] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [error, setError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const partnerId = userProfileContext.userProfile.partnerId;

    const [data, setData] = useState({
        rows: [],
        columns: DocumentColumns(setPage)
    });

    useEffect(() => {
        getDocuments({
            page,
            pageSize,
            sortModel,
            partnerId,
            searchText,
            filter,
            setIsFetching,
            setTotalRows,
            setData,
            handleError
        });
    }, [page, pageSize, sortModel, partnerId, searchText, filter]);

    const requestSearch = (searchValue) => {
        setIsFetching(true);
        setSearchText(searchValue);

        const options = {
            pageNumber: 0,
            pageSize: 10,
            searchText: searchValue,
            partnerId
        };

        documentService
            .getDocumentsBySearch(options)
            .then(({ rows, totalRows }) => {
                setIsFetching(false);
                setTotalRows(totalRows);
                setData((oldData) => ({ ...oldData, rows }));
            })
            .catch(handleError);
    };

    const onSearchTermChange = (term) => {
        setSearchText(term);
        debounceSearch(term);
    };

    const debounceSearch = useCallback(
        debounce((term) => requestSearch(term), 500),
        []
    );

    const onClearSearch = () => {
        requestSearch('');
    };

    const requestFilter = (filterModel) => {
        setIsFetching(true);

        const fetchOptions = {
            pageNumber: page,
            pageSize,
            filter: filterModel.items[0],
            partnerId
        };

        if (isNil(filterModel.items[0])) {
            return;
        }

        documentService
            .getDocumentsByFilter(fetchOptions)
            .then(({ rows, totalRows }) => {
                setTotalRows(totalRows);
                setData((oldData) => ({ ...oldData, rows }));
                setIsFetching(false);
            })
            .catch(handleError);
    };

    const onFilterModelChange = (filterModel) => {
        setFilter(filterModel);
        debounceFilter(filterModel);
    };

    const debounceFilter = useCallback(
        debounce((filterModel) => requestFilter(filterModel), 800),
        []
    );

    const handleError = () => {
        setIsFetching(false);
        notifyError('Something went wrong while fetching the documents');
    };

    const closeErrorModal = () => {
        setError(false);
    };

    const reloadOnError = () => {
        setPage(0);
        setPageSize(10);
        setSortModel([
            {
                field: 'createdAt',
                sort: 'desc'
            }
        ]); // setting the grid to  have its default options
        closeErrorModal();
    };

    return (
        <DocumentGridElement
            data={data}
            pageSize={pageSize}
            searchText={searchText}
            totalRows={totalRows}
            isFetching={isFetching}
            sortModel={sortModel}
            error={error}
            setPageSize={setPageSize}
            setPage={setPage}
            setSortModel={setSortModel}
            onClearSearch={onClearSearch}
            onSearchTermChange={onSearchTermChange}
            onFilterModelChange={onFilterModelChange}
            closeErrorModal={closeErrorModal}
            reloadOnError={reloadOnError}
        />
    );
}
