import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { nigelTheme } from './assets/theme/nigel.theme';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './utils/history';
import AppContainer from './app/app.container';
import { AUTH0_BASE_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } from './config';
require('dotenv').config();

const onRedirectCallback = (appState) => {
    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const providerConfig = {
    domain: AUTH0_BASE_DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    ...(AUTH0_AUDIENCE ? { audience: AUTH0_AUDIENCE } : null),
    redirectUri: window.location.origin,
    onRedirectCallback
};

ReactDOM.render(
    <Auth0Provider {...providerConfig}>
        <React.StrictMode>
            <ThemeProvider theme={nigelTheme}>
                <AppContainer />
            </ThemeProvider>
        </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
);
