import { colors } from '../../assets/styles/colors';

export const styles = {
    dashboardButton: {
        border: `dashed ${colors.navyOne} 1.5px`,
        borderRadius: '5px',
        height: 200,
        width: '100%',
        cursor: 'pointer',
        display: 'flex-box',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10
    },
    buttonText: {
        maxWidth: 200,
        marginLeft: 20,
        marginTop: 10,
        padding: 10
    },
    flex: {
        justifyContent: 'center',
        display: 'flex'
    },
    text: { wordWrap: 'break-word', fontSize: 15 }
};
