import { createTheme } from '@material-ui/core/styles';

export const nigelTheme = createTheme({
    palette: {
        primary: {
            main: '#273e5c'
        },
        secondary: {
            main: '#058092'
        },
        error: {
            main: '#A50000'
        },
        success: {
            main: '#009433'
        },
        warning: {
            main: '#C69944'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                borderRadius: '25px'
            }
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: '#058092'
                }
            }
        },
        MuiTextField: {
            root: {
                '& fieldset': {
                    borderRadius: '25px'
                }
            }
        },
        MuiTablePagination: {
            root: {
                color: '#058092'
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: '10px',
                position: 'relative'
            }
        },
        MuiDialogContent: {
            root: {
                padding: 40
            }
        },
        MuiDialogActions: {
            root: {
                padding: 10,
                margin: 0,
                marginBottom: 10
            }
        }
    },
    white: '#FFFFFF',
    transparentButton: 'rgba(0, 0, 0, 0.04)',
    transparentButtonHover: 'rgba(0, 0, 0, 0.08)'
});
