import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionItem } from './accordion-item';
import { isEmpty, map } from 'lodash';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { MetadataDeleteModal } from './metadata-delete-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styles } from './document-metadata-preview.styles';
import { MetadataModificationModal } from './metadata-modification-modal';

export function DocumentMetadataPreviewElement({ dataURI, data }) {
    const { internalOcr } = data;
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [ocrEntityToAdd, setOcrEntityToAdd] = useState('');
    const [ocrDataToEdit, setOcrDataToEdit] = useState({
        date: '',
        email: '',
        url: '',
        phoneNumber: ''
    });
    const [ocrDataToDelete, setOcrDataToDelete] = useState({
        ocrEntity: '',
        data: ''
    });

    const makeAccordionData = () => {
        const accordionData = makeAccordionUI();

        if (internalOcr) {
            if (data && !isEmpty(internalOcr.dates)) {
                accordionData[0].data = internalOcr.dates.map((doc) => {
                    return doc.name;
                });
            }

            if (data && !isEmpty(internalOcr.emails)) {
                accordionData[1].data = internalOcr.emails.map((doc) => {
                    return doc.name;
                });
            }

            if (data && !isEmpty(internalOcr.urls)) {
                accordionData[2].data = internalOcr.urls.map((doc) => {
                    return doc.name;
                });
            }

            if (data && !isEmpty(internalOcr.phoneNumbers)) {
                accordionData[3].data = internalOcr.phoneNumbers.map((doc) => {
                    return doc.name;
                });
            }
        }

        return accordionData;
    };

    const makeAccordionUI = () => {
        const accordionUI = [];

        const dates = {
            accordionTitle: 'Dates',
            accordionTitleIcon: ['fas', 'calendar'],
            data: []
        };
        accordionUI.push(dates);

        const emails = {
            accordionTitle: 'Emails',
            accordionTitleIcon: ['fas', 'at'],
            data: []
        };
        accordionUI.push(emails);

        const urls = {
            accordionTitle: 'Websites',
            accordionTitleIcon: ['fas', 'globe'],
            data: []
        };
        accordionUI.push(urls);

        const phoneNumbers = {
            accordionTitle: 'Phone Numbers',
            accordionTitleIcon: ['fas', 'phone'],
            data: []
        };
        accordionUI.push(phoneNumbers);

        return accordionUI;
    };

    const downloadPDF = () => {
        const linkSource = `${dataURI}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = data.name;
        downloadLink.click();
    };

    const closeModal = () => {
        setIsOpen(false);
        setOcrEntityToAdd('');
        setIsEditing(false);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setOcrDataToDelete({ ocrEntity: '', data: '' });
    };

    const openDataChangeModal = () => {
        setIsOpen(true);
    };

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };

    const accordionData = makeAccordionData();

    const renderAccordionData = (ocrEntity) => {
        return (
            <AccordionItem
                key={uuid()}
                ocrEntity={ocrEntity}
                openDataChangeModal={openDataChangeModal}
                setOcrEntityToAdd={setOcrEntityToAdd}
                setOcrDataToEdit={setOcrDataToEdit}
                setOcrDataToDelete={setOcrDataToDelete}
                ocrDataToEdit={ocrDataToEdit}
                setIsEditing={setIsEditing}
                openDeleteModal={openDeleteModal}
                status={data.status}
            />
        );
    };

    return (
        <div>
            <MetadataModificationModal
                open={isOpen}
                handleClose={closeModal}
                ocrEntity={ocrEntityToAdd}
                oldData={ocrDataToEdit}
                isEditing={isEditing}
            />
            <MetadataDeleteModal
                open={isDeleteModalOpen}
                handleClose={closeDeleteModal}
                ocrDataToDelete={ocrDataToDelete}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h6" component="p" style={{ marginTop: 10 }}>
                    Document Metadata
                </Typography>
                <Tooltip title="Download document">
                    <IconButton style={styles.mb} onClick={downloadPDF}>
                        <FontAwesomeIcon icon="file-download" />
                    </IconButton>
                </Tooltip>
            </div>
            {map(accordionData, renderAccordionData)}
        </div>
    );
}

DocumentMetadataPreviewElement.propTypes = {
    data: PropTypes.object,
    dataURI: PropTypes.string
};
