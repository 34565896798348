import React, { useRef, useState } from 'react';
import { Typography, Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/colors';

export function FilePickerInput(props) {
    const filePicker = useRef();
    const { filter, processFile, disabled } = props;
    const [fileText, setFileText] = useState(disabled ? '-' : 'No file selected');
    const [fileUploaded, setFileUploaded] = useState(false);

    const onChangeFile = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setFileUploaded(true);
            setFileText(file.name);
            processFile(file);
        } else {
            clear();
        }
        e.target.value = null;
    };

    const clear = () => {
        setFileUploaded(false);
        setFileText('No file selected');
        processFile(null);
    };

    return (
        <>
            <Typography
                style={{
                    width: '100%',
                    lineHeight: 1,
                    color: fileUploaded ? colors.navyOne : '#999',
                    marginBottom: 5,
                    marginTop: 10
                }}
            >
                Message Image
            </Typography>
            <Box display="flex" style={{ width: '100%', borderBottom: '1px solid #888' }}>
                <Typography
                    style={{ width: '100%', lineHeight: 2, color: disabled ? '#999' : 'black' }}
                >
                    {fileText}
                </Typography>
                <input
                    type="file"
                    id="file"
                    ref={filePicker}
                    style={{ display: 'none' }}
                    accept={filter}
                    onChange={onChangeFile}
                />
                {fileUploaded && (
                    <Button disabled={disabled} size="small" variant="text" onClick={clear}>
                        Clear
                    </Button>
                )}
                {!fileUploaded && (
                    <Button
                        disabled={disabled}
                        size="small"
                        variant="text"
                        color="secondary"
                        onClick={() => {
                            filePicker.current.click();
                        }}
                    >
                        Upload
                    </Button>
                )}
            </Box>
        </>
    );
}

FilePickerInput.propTypes = {
    disabled: PropTypes.bool,
    processFile: PropTypes.func,
    filter: PropTypes.string
};
