import React from 'react';
import PropTypes from 'prop-types';
import { styles, useStyles } from './customer-grid.styles';
import { DataGrid } from '@material-ui/data-grid';
import { CustomerGridToolBar } from './customer-grid-tool-bar';
import { isEmpty } from 'lodash';

export function CustomerGridElement(props) {
    const classes = useStyles();
    const {
        data,
        pageSize,
        setPageSize,
        setPage,
        searchText,
        setSortModel,
        sortModel,
        totalRows,
        isFetching,
        onClearSearch,
        onSearchTermChange,
        onFilterModelChange
    } = props;
    const { rows } = data;

    const setModelSort = (model) => {
        if (isEmpty(model)) {
            setSortModel([{ ...sortModel[0], sort: sortModel[0].sort === 'asc' ? 'desc' : 'asc' }]);
            return;
        }
        setSortModel(model);
    };

    return (
        <div styles={styles.container}>
            <DataGrid
                {...data}
                autoHeight
                pagination
                paginationMode="server"
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
                rowsPerPageOptions={[5, 10, 20]}
                onPageChange={setPage}
                filterMode="server"
                onFilterModelChange={(filterModel) => onFilterModelChange(filterModel)}
                sortingMode="server"
                onSortModelChange={(model) => setModelSort(model)}
                checkboxSelection={false}
                loading={isFetching}
                components={{ Toolbar: CustomerGridToolBar }}
                rows={rows}
                columns={data.columns}
                rowCount={totalRows}
                sortModel={sortModel}
                disableColumnSelector
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => onSearchTermChange(event.target.value),
                        clearSearch: onClearSearch
                    }
                }}
                className={classes.hideRightSeparator}
            />
            <div style={styles.whiteSpace}></div>
        </div>
    );
}

CustomerGridElement.propTypes = {
    data: PropTypes.object,
    pageSize: PropTypes.number,
    isFetching: PropTypes.bool,
    totalRows: PropTypes.number,
    setPageSize: PropTypes.func,
    setPage: PropTypes.func,
    sortModel: PropTypes.array,
    setSortModel: PropTypes.func,
    searchText: PropTypes.string,
    onClearSearch: PropTypes.func,
    onSearchTermChange: PropTypes.func,
    onFilterModelChange: PropTypes.func
};
