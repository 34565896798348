import { notifyError, notifySuccess } from '../../../services/toast.service';
import { fileService } from '../../../services/file.service';

export const processFile = async ({ file, setFile, setOpenCustomersModal }) => {
    if (!validFileExtension(file.type) || !validFileSize(file.size)) {
        setFile(null);
        return;
    }
    setFile(file);
    setOpenCustomersModal(true);
};

export const submitFile = async ({
    file,
    customers,
    partnerId,
    userProfileContext,
    externalUserId,
    history,
    setLoading
}) => {
    setLoading(true);

    fileService
        .uploadPartnerFile(
            {
                size: file.size,
                name: file.name,
                uri: file.uri,
                type: file.type,
                file: file
            },
            partnerId,
            userProfileContext.userProfile,
            customers,
            externalUserId
        )
        .then(() => {
            setLoading(false);
            notifySuccess(
                'Your document has been uploaded and will soon be ready to be shared with customers.'
            );
            history.push('documents');
        })
        .catch(() => {
            setLoading(false);
            notifyError('Something went wrong while uploading your document.');
        });
};

const validFileExtension = (fileType) => {
    switch (fileType) {
        case 'image/jpeg':
        case 'image/png':
        case 'application/pdf':
            return true;
        default:
            notifyError('Incorrect file type detected. Please upload either a JPEG, PNG, or PDF');
            return false;
    }
};

const validFileSize = (fileSize) => {
    if (fileSize > 4000000) {
        notifyError('Invalid file size. Please upload a file smaller than 4MB');
        return false;
    }

    return true;
};
