import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@material-ui/icons/Close';
import { successModal as styles } from './success-modal.styles';
import { map } from 'lodash';

export function SuccessModal(props) {
    const renderMainText = (text) => {
        return (
            <Typography style={styles.centerSelf} variant="h6">
                {text}
            </Typography>
        );
    };

    const renderSubText = (text) => {
        return (
            <Typography key={text} style={styles.textMessage} variant="subtitle1" component="p">
                {text}
            </Typography>
        );
    };

    const {
        handleClose,
        open,
        primaryAction,
        secondaryAction,
        mainText,
        subText,
        buttonText1,
        buttonText2
    } = props;
    return (
        <Dialog
            container={() => document.getElementById('app-layout')}
            aria-labelledby="successful-action"
            open={open}
        >
            <IconButton onClick={handleClose} style={styles.cancelIcon}>
                <CloseIcon fontSize="small" />
            </IconButton>
            <MuiDialogContent style={styles.dialog}>
                <Grid spacing={3} direction="column" container>
                    <FontAwesomeIcon
                        style={styles.iconStyle}
                        icon={['far', 'check-circle']}
                        color={colors.turquoiseOne}
                        size="5x"
                    />
                    {map(mainText, renderMainText)}
                    {map(subText, renderSubText)}
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions style={styles.btnContainer}>
                <Button
                    style={styles.btnWidth}
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={secondaryAction}
                >
                    {buttonText1}
                </Button>
                <Button size="large" variant="contained" color="secondary" onClick={primaryAction}>
                    {buttonText2}
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}

SuccessModal.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    secondaryAction: PropTypes.func,
    primaryAction: PropTypes.func,
    mainText: PropTypes.array,
    subText: PropTypes.array,
    buttonText1: PropTypes.string,
    buttonText2: PropTypes.string
};
