export const colors = {
    transparent: 'transparent',

    opacityBG: 'rgba(52, 52, 52, 0.8)',
    iconOpacityBG: 'rgba(112, 197, 193, 0.25)',
    grayBG: 'rgba(220, 220, 220, 0.7)',

    blackOne100: '#000000',
    blackOne25: 'rgba(0, 0, 0, 0.25)',

    whiteTwo100: '#F8F8F8',
    whiteThree100: '#FFFFFF',

    grayOne100: '#444444',
    grayTwo100: '#6B6B6B',

    darkBlue100: '#051E56',
    lightBlue100: '#B3C7F9',

    darkBlueGray100: '#A7A6AB',
    lightBlueGray100: '#E8E8EF',

    navyOne: '#273e5c',
    turquoiseOne: '#009490',
    turquoiseTwo: '#058092',
    turquoiseThree: '#43BCAD',
    redOne: '#A50000',
    whiteOne: '#FFFFFF',
    greyOne: '#adb4bc',
    greenOne: '#009433',
    amberOne: '#C69944',
    hotPinkOne: '#E62460'
};
