import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RoleFormElement } from './role-form.element';

const onFormUpdate = ({
    isRoleNameValid,
    isRoleDescriptionValid,
    isPermissionsValid,
    setIsValidForm
}) => {
    const isValid = isRoleNameValid && isRoleDescriptionValid && isPermissionsValid;
    setIsValidForm(isValid);
};

export function RoleForm(props) {
    const {
        isEdit,
        roleName,
        setRoleName,
        roleDescription,
        setRoleDescription,
        permissions,
        setPermissions,
        active,
        setActive,
        loading,
        submissionError,
        submitRole,
        closeErrorModal,
        onCancel
    } = props;

    const [isRoleNameValid, setIsRoleNameValid] = useState(false);
    const [isRoleDescriptionValid, setIsRoleDescriptionValid] = useState(false);
    const [isPermissionsValid, setIsPermissionsValid] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);

    useEffect(() => {
        setIsRoleNameValid(validateRoleAndDescription(roleName));
        setIsRoleDescriptionValid(validateRoleAndDescription(roleDescription));
        onFormUpdate({
            isRoleNameValid,
            isRoleDescriptionValid,
            isPermissionsValid,
            setIsValidForm
        });
    }, [roleName, roleDescription, isRoleNameValid, isRoleDescriptionValid, isPermissionsValid]);

    const setRoleNameForValidation = (e) => {
        setRoleName(extractValue(e));
        setIsRoleNameValid(validateRoleAndDescription(extractValue(e)));
    };

    const setRoleDescriptionForValidation = (e) => {
        setRoleDescription(extractValue(e));
        setIsRoleDescriptionValid(validateRoleAndDescription(extractValue(e)));
    };

    const validateRoleAndDescription = (input) => {
        const hasNumber = /\d/.test(input);
        return input.length >= 3 && !hasNumber;
    };

    const extractValue = (e) => {
        return e.target.value;
    };

    return (
        <div>
            <RoleFormElement
                roleName={roleName}
                isRoleNameValid={isRoleNameValid}
                setRoleNameForValidation={setRoleNameForValidation}
                roleDescription={roleDescription}
                isRoleDescriptionValid={isRoleDescriptionValid}
                setRoleDescriptionForValidation={setRoleDescriptionForValidation}
                active={active}
                setActive={setActive}
                permissions={permissions}
                setPermissions={setPermissions}
                isPermissionsValid={isPermissionsValid}
                setIsPermissionsValid={setIsPermissionsValid}
                isValidForm={isValidForm}
                submissionError={submissionError}
                loading={loading}
                isEdit={isEdit}
                submitRole={submitRole}
                onFormUpdate={onFormUpdate}
                closeErrorModal={closeErrorModal}
                onCancel={onCancel}
            />
        </div>
    );
}

RoleForm.propTypes = {
    isEdit: PropTypes.bool,
    roleName: PropTypes.string,
    setRoleName: PropTypes.func,
    roleDescription: PropTypes.string,
    setRoleDescription: PropTypes.func,
    permissions: PropTypes.array,
    setPermissions: PropTypes.func,
    active: PropTypes.bool,
    setActive: PropTypes.func,
    loading: PropTypes.bool,
    submissionError: PropTypes.bool,
    submitRole: PropTypes.func,
    closeErrorModal: PropTypes.func,
    onCancel: PropTypes.func
};
