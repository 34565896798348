import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RoleForm } from './role-form.container';
import { rolesService } from '../../../../services';
import { useHistory } from 'react-router';
import { notifyError, notifySuccess } from '../../../../services/toast.service';

export function EditRoleForm(props) {
    const {
        roleId,
        roleName,
        setRoleName,
        roleDescription,
        setRoleDescription,
        active,
        setActive,
        permissions,
        setPermissions,
        loading,
        setLoading
    } = props;

    const [submissionError, setSubmissionError] = useState(false);

    const history = useHistory();

    const submitRole = async (event) => {
        setLoading(true);

        event.preventDefault();

        const role = {
            _id: roleId,
            roleName,
            roleDescription,
            permissions,
            isActive: active
        };

        rolesService
            .editRole(role)
            .then(() => {
                notifySuccess(`Role ${roleName} was successfully edited`);
                gotoRolesPage();
            })
            .catch(() => {
                notifyError(`Something went wrong whilst editing the role`);
            });
    };

    const closeErrorModal = () => {
        setSubmissionError(false);
    };

    const gotoRolesPage = () => {
        history.push('/roles');
    };

    return (
        <div>
            <RoleForm
                isEdit={true}
                roleName={roleName}
                setRoleName={setRoleName}
                roleDescription={roleDescription}
                setRoleDescription={setRoleDescription}
                permissions={permissions}
                setPermissions={setPermissions}
                active={active}
                setActive={setActive}
                loading={loading}
                submissionError={submissionError}
                submitRole={submitRole}
                closeErrorModal={closeErrorModal}
                onCancel={gotoRolesPage}
            />
        </div>
    );
}

EditRoleForm.propTypes = {
    roleId: PropTypes.string,
    roleName: PropTypes.string,
    setRoleName: PropTypes.func,
    roleDescription: PropTypes.string,
    setRoleDescription: PropTypes.func,
    active: PropTypes.bool,
    setActive: PropTypes.func,
    permissions: PropTypes.array,
    setPermissions: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func
};
