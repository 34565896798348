import React, { useState, useContext } from 'react';
import { RoleForm } from './role-form.container';
import { rolesService } from '../../../../services';
import { useHistory } from 'react-router';
import { UserProfileContext } from '../../../../store/user-profile.context';
import { v4 } from 'uuid';
import { notifyError, notifySuccess } from '../../../../services/toast.service';

export function AddRoleForm() {
    const [roleName, setRoleName] = useState('');
    const [roleDescription, setRoleDescription] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const userProfileContext = useContext(UserProfileContext);

    const submitRole = async (event) => {
        setLoading(true);
        event.preventDefault();

        const role = {
            roleId: v4(),
            roleName,
            roleDescription,
            permissions,
            isActive: true,
            partnerId: userProfileContext.userProfile.partnerId
        };

        rolesService
            .addRole(role)
            .then(() => {
                setLoading(false);
                cleanUpForm();
                notifySuccess(`New role added successfully`);
                gotoRolesPage();
            })
            .catch(() => {
                notifyError(`Something went wrong whilst adding the role`);
            });
    };

    const cleanUpForm = () => {
        setRoleName('');
        setRoleDescription('');
    };

    const history = useHistory();

    const gotoRolesPage = () => {
        history.push('roles');
    };

    return (
        <div>
            <RoleForm
                isEdit={false}
                roleName={roleName}
                setRoleName={setRoleName}
                roleDescription={roleDescription}
                setRoleDescription={setRoleDescription}
                permissions={permissions}
                setPermissions={setPermissions}
                active={active}
                setActive={setActive}
                loading={loading}
                submitRole={submitRole}
                onCancel={gotoRolesPage}
            />
        </div>
    );
}
