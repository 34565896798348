import React, { useState, useEffect, useCallback, useContext } from 'react';
import { columnDefinitions } from './user-profile-grid-col-def';
import { UserProfileGridElement } from './user-profile-grid.element';
import { userProfileService } from '../../../../services';
import { isEmpty, isNil, debounce } from 'lodash';
import { UserProfileContext } from '../../../../store/user-profile.context';
import { notifyError } from '../../../../services/toast.service';

export function UserProfileGrid(props) {
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [sortModel, setSortModel] = useState([
        {
            field: 'firstName',
            sort: 'asc'
        }
    ]);
    const [filter, setFilter] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [error, setError] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const userProfileContext = useContext(UserProfileContext);
    const partnerId = userProfileContext.userProfile.partnerId;

    useEffect(() => {
        getUsers();
    }, [page, pageSize, sortModel, userProfileContext.userProfile.partnerId]);

    const getUsers = () => {
        const fetchOptions = {
            pageNumber: page,
            pageSize,
            sortBy: sortModel[0],
            partnerId
        };

        setIsFetching(true);

        if (!isEmpty(searchText)) {
            userProfileService
                .getUsersBySearch({ ...fetchOptions, searchText })
                .then(({ rows, totalRows }) => {
                    setTotalRows(totalRows);
                    setData({ ...data, rows: rows });
                    setIsFetching(false);
                })
                .catch(handleError);
            return;
        }

        if (!isNil(filter.items)) {
            if (!isEmpty(filter?.items[0]?.value)) {
                userProfileService
                    .getUsersByFilter({ ...fetchOptions, filter: filter.items[0] })
                    .then(({ rows, totalRows }) => {
                        setTotalRows(totalRows);
                        setData({ ...data, rows: rows });
                        setIsFetching(false);
                    })
                    .catch(handleError);
                return;
            }
        }

        userProfileService
            .getUsers(fetchOptions)
            .then(({ rows, totalRows }) => {
                setTotalRows(totalRows);
                setData({ ...data, rows: rows });
                setIsFetching(false);
            })
            .catch(handleError);
    };

    const [data, setData] = useState({
        rows: [],
        // eslint-disable-next-line no-use-before-define
        columns: columnDefinitions(getUsers)
    });

    const requestSearch = (searchValue) => {
        setIsFetching(true);
        setSearchText(searchValue);

        const options = {
            pageNumber: 0,
            pageSize: 10,
            searchText: searchValue,
            partnerId
        };

        userProfileService
            .getUsersBySearch(options)
            .then(({ rows, totalRows }) => {
                setIsFetching(false);
                setTotalRows(totalRows);
                setData({ ...data, rows });
            })
            .catch(handleError);
    };

    const onSearchTermChange = (term) => {
        setSearchText(term);
        debounceSearch(term);
    };

    const debounceSearch = useCallback(
        debounce((term) => requestSearch(term), 500),
        []
    );

    const onClearSearch = () => {
        requestSearch('');
    };

    const requestFilter = (filterModel) => {
        setIsFetching(true);

        const fetchOptions = {
            pageNumber: page,
            pageSize,
            filter: filterModel.items[0]
        };

        if (isNil(filterModel.items[0])) {
            return;
        }

        userProfileService
            .getUsersByFilter(fetchOptions)
            .then(({ rows, totalRows }) => {
                setTotalRows(totalRows);
                setData({ ...data, rows: rows });
                setIsFetching(false);
            })
            .catch(handleError);
    };

    const onFilterModelChange = (filterModel) => {
        setFilter(filterModel);
        debounceFilter(filterModel);
    };

    const debounceFilter = useCallback(
        debounce((filterModel) => requestFilter(filterModel), 1000),
        []
    );

    const handleError = () => {
        setIsFetching(false);
        notifyError('Something went wrong while fetching the users');
    };

    const closeErrorModal = () => {
        setError(false);
    };

    const reloadOnError = () => {
        setPage(0);
        setPageSize(10);
        setSortModel([
            {
                field: 'firstName',
                sort: 'asc'
            }
        ]); // setting the grid to  have its default options
        closeErrorModal();
    };

    return (
        <UserProfileGridElement
            data={data}
            pageSize={pageSize}
            searchText={searchText}
            totalRows={totalRows}
            isFetching={isFetching}
            sortModel={sortModel}
            error={error}
            setPageSize={setPageSize}
            setPage={setPage}
            setSortModel={setSortModel}
            onClearSearch={onClearSearch}
            onSearchTermChange={onSearchTermChange}
            onFilterModelChange={onFilterModelChange}
            closeErrorModal={closeErrorModal}
            reloadOnError={reloadOnError}
        />
    );
}

UserProfileGrid.propTypes = {};
